import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './PanoView.scss'
import {
  changePanoViewPosition,
  clickMenuPano,
  saveAsTicket,
  saveProjectComments,
  screenshotMode,
  setIsChangePano,
  setSaveComments,
  setPanoComments,
  setSpriteSelectedPano,
  setViewPano,
  setCommentPosition
} from 'redux/reducers/ui/uiSlice'
import { updateCommentProjectPano } from 'redux/reducers/projects/projectsSlice'
import { slice, sprites } from 'constants/ui'
import arrowPNG from 'assets/shapes/arrows-with-transparent-red.png'
import circlePNG from 'assets/shapes/circe_normal_red.png'
import squarePNG from 'assets/shapes/square_red.png'
import ModalDisplay from 'components/common/Modal/Modal'
import MyCheckBox from 'components/common/CheckBox/CheckBox'
import { commentTypes } from 'constants/constants'
import Button from 'components/common/Button/Button'
import { Chip, TextareaAutosize } from '@material-ui/core'
import { nanoid } from 'nanoid'
import Comments from 'components/Comments/Comments'
import ItemsList from 'components/common/List/List'
import Loading from 'components/common/Loading'
import { Pannellum } from 'pannellum-react'
import propTypes from 'prop-types'

const DEFAULT = 'DEFAULT'

/*
class CanvasSection extends React.Component {
  isDrawing = false
  lastX = 0
  lastY = 0

  componentDidMount = () => {
    if (!this.refCanvas) return
    this.refCanvas.addEventListener('pointerdown', (event) => {
      let x = event.offsetX
      let y = event.offsetY
      this.isDrawing = true
      this.lastX = x
      this.lastY = y
    })

    this.refCanvas.addEventListener('pointerup', () => {
      this.isDrawing = false
    })

    this.refCanvas.addEventListener('pointermove', (event) => {
      if (!this.isDrawing) return
      let x = event.offsetX
      let y = event.offsetY

      let ctx = this.refCanvas.getContext('2d')

      ctx.beginPath()
      ctx.lineWidth = 4
      ctx.lineCap = 'round'
      ctx.moveTo(this.lastX, this.lastY)
      ctx.lineTo(x, y)
      ctx.stroke()

      this.lastX = x
      this.lastY = y
    })
  }

  handleAdd = () => {
    this.props.onAdd(this.refCanvas.toDataURL())
  }

  render() {
    return (
      <div className='canvas-section'>
        <div className='canvas-container'>
          <canvas
            ref={(ref) => (this.refCanvas = ref)}
            width='300'
            height='300'
          />
          <div className='canvas-container_buttons'>
            <button className='btnAddCanvasPano' onClick={this.handleAdd}>
              Add
            </button>
          </div>
        </div>
      </div>
    )
  }
}
*/

const {
  pano: {
    // addOrEdit: { ADD_PANO, EDIT_PANO },
    spriteSelected: {
      ARROW_DRAW_PANO,
      CIRCE_DRAW_PANO,
      SQUARE_DRAW_PANO
      // MY_DRAW_PANO
    }
  }
} = slice

const multiplyScalar = 0.2 // old ver is 0.5
/*
const changeMeshToSpritePano = (dataMesh) => {
  const ratio = 1 / multiplyScalar
  return {
    id: dataMesh.idCmt,
    type: dataMesh.type,
    vector3: {
      x: dataMesh.position.x * ratio,
      y: dataMesh.position.y * ratio,
      z: dataMesh.position.z * ratio
    },

    scale: dataMesh.ratio || 1
  }
}
*/
const changeMeshsToSpriteGroupPano = (dataMeshs) => {
  // multiplyScalar = 0.2. You need check config of SceneManager and contanst
  const ratio = 1 / multiplyScalar
  const newDataSpriteGroupPano = dataMeshs.map((item) => {
    return {
      id: item.idCmt,
      type: item.type,
      vector3: {
        x: item.position.x * ratio,
        y: item.position.y * ratio,
        z: item.position.z * ratio
      },
      commentDetail: {
        user: 'Mihajlov',
        createdDate: '16/8/2020',
        content: item.comment
      },
      scale: item.ratio || 1
    }
  })
  return newDataSpriteGroupPano
}

const PanoView = (props) => {
  const pannellumRef = useRef(null)
  const dispatch = useDispatch()
  const ui = useSelector((state) => state.ui.slider.pano)
  const [commentCheckedValues, setCommentCheckedValues] = useState()
  const [openCommentOptionsModal, setOpenCommentOptionsModal] = useState(false)
  const [openCommentTextModal, setOpenCommentTextModal] = useState(false)
  const [addNewComment, setAddNewComment] = useState('')
  const [userData, setUserData] = useState({})
  // const refContainer = useRef(null)
  const sceneManager = useRef(null)
  // const selectedSprite = useRef(null)
  const uiConfigure = useSelector((state) => state.ui.configre)
  const selectedProject = useSelector((state) => state.projects.selectedProject)
  const rawDrawOnSlide = ui.rawDraw
  const { id: projectID } = selectedProject || {
    version: '',
    id: ''
  }
  const [pannellumLoading, setPannellumLoading] = useState(true)
  const {
    configre: {
      cameraControls,
      selectedTrimValue,
      selectedExterior,
      selectedInterior
      // showComments
    },
    saveComments
  } = useSelector((state) => state.ui)
  const selectedCameraControl =
    cameraControls &&
    Array.isArray(cameraControls.data) &&
    cameraControls.data.find(({ value }) => value === cameraControls.value)

  const setSprite = (dataUrl, spriteSelected) => {
    // sceneManager.current.setSprite(dataUrl, spriteSelected);
  }
  const getSpriteGroupCurr = () => {
    // return sceneManager.current?.getSpriteGroup();
  }

  useEffect(() => {
    setUserData(JSON.parse(window.localStorage.getItem('userData')))
  }, [])

  useEffect(() => {
    setPannellumLoading(true)
    const findCanvas = document.getElementsByTagName('canvas')
    if (findCanvas?.length) {
      for (const canvas of findCanvas) {
        canvas.remove()
      }
    }
    // sceneManager.current = new SceneManager(refContainer.current, props.src);
    // sceneManager.current.setSpriteGroupAnDraw(ui.spriteGroup);
    /*
    sceneManager.current.addEventListener(
      "commentChanged",
      (visibleCmt, visibleCmtInput, x, y, selectedSpriteObj) => {
        selectedSprite.current = selectedSpriteObj;
        //dispatch(setIdSelectedComment(selectedSpriteObj?.idCmt || null));
      }
    );

    sceneManager.current.addEventListener(
      "changePano",
      (flagIsChange, flagIsCommnent, rawSpriteObj) => {
        flagIsChange !== ui.isChange && dispatch(setIsChangePano(flagIsChange));
        flagIsCommnent !== undefined &&
          dispatch(changeIsComment(flagIsCommnent));
        if (rawSpriteObj) {
          dispatch(saveRawDrawPano(changeMeshToSpritePano(rawSpriteObj)));
          setOpenCommentOptionsModal(true);
        }
      }
    );
     */
  }, [props.src])

  useEffect(() => {
    // sceneManager.current.setEditable(!ui.viewMode);
    /*
    const panoElm = document.getElementById("pano");
    panoElm.addEventListener("mouseover", () => {
      if (ui.viewMode) {
        windowDisableScroll();
      } else {
        windowEnableScroll();
      }
    });
    panoElm.addEventListener("mouseout", () => {
      windowEnableScroll();
    });
     */
  }, [ui.viewMode])

  useEffect(() => {
    if (ui.spriteSelected === DEFAULT) {
      setSprite(null, null)
    } else if (ui.spriteSelected === ARROW_DRAW_PANO) {
      setSprite(arrowPNG, 'ARROW')
    } else if (ui.spriteSelected === CIRCE_DRAW_PANO) {
      setSprite(circlePNG, 'CIRCE')
    } else if (ui.spriteSelected === SQUARE_DRAW_PANO) {
      setSprite(squarePNG, 'SQUARE')
    }
  }, [ui.spriteSelected])

  useEffect(() => {
    /*
    sceneManager.current.setSpriteGroupAndDraw(
      ui.isShow ? ui.spriteGroup : [],
      ui.panoHighlightedAnnotation
    );
     */
    // note: ui.isShow change => uiConfigure.content.status change too. Check it if you want fix
  }, [ui.spriteGroup, uiConfigure.content.status, ui.panoHighlightedAnnotation])

  useEffect(() => {
    // sceneManager.current.isAdd = ui.addOrEdit === EDIT_PANO ? false : true;
  }, [ui.addOrEdit])

  useEffect(() => {
    const comments = []

    const filterProjectComment = saveComments.filter(
      (projectId) =>
        projectId.projectId === projectID &&
        projectId.cameraControlId === selectedCameraControl.value
    )
    // eslint-disable-next-line array-callback-return
    filterProjectComment.map((item) => {
      comments.push({
        id: item.id,
        commentDetail: item.commentDetail,
        ...item.canvas
      })
    })
    dispatch(setPanoComments(comments))
  }, [saveComments])

  useEffect(() => {
    if (ui.countChangePano > 0) {
      dispatch(
        updateCommentProjectPano(
          changeMeshsToSpriteGroupPano(getSpriteGroupCurr())
        )
      )
      dispatch(setIsChangePano(false))
      dispatch(setViewPano(true))
      dispatch(clickMenuPano('SHOW_MENU'))
    }
  }, [ui.countChangePano])

  const onSelectedCommentOption = () => {
    setOpenCommentOptionsModal(false)
    setOpenCommentTextModal(true)
  }

  const onCommentModalClose = () => {
    setOpenCommentOptionsModal(false)
  }

  const onChangeComment = (e) => {
    const currentText = e.target.value
    setAddNewComment(currentText)
  }

  const commentTitle = {
    trim_comment: 'Trim comment',
    color_comment: 'Color comment',
    camera_comment: 'Camera comment',
    config_comment: 'Config comment'
  }

  const titleType = {
    trim_comment: selectedTrimValue.value,
    color_comment: selectedExterior.value,
    camera_comment: selectedCameraControl.title,
    config_comment:
      'Trim:' +
      selectedTrimValue.value +
      ' ' +
      'Color:' +
      selectedExterior.value +
      ' ' +
      'View:' +
      ' ' +
      selectedCameraControl.title
  }

  const commentType = {
    2: 'Exterior Comment',
    1: 'Interior Comment'
  }

  const onCommentComplete = async () => {
    const { version, id: projectID } = selectedProject || {
      version: '',
      id: ''
    }
    if (selectedProject?.id && selectedProject?.version) {
      // dispatch(clickMenu("SHOW_MENU"));
      setOpenCommentTextModal(false)
      dispatch(screenshotMode({ status: true }))
      let imageURL
      await new Promise((resolve) =>
        setTimeout(async () => {
          imageURL = await sceneManager.current.saveAsImage(projectID)
          resolve()
        }, 1000)
      )
      dispatch(
        setSaveComments({
          id: nanoid(5),
          exteriorColorId: selectedExterior.value,
          cameraControlId: selectedCameraControl.value,
          interiorId: selectedInterior.value,
          commentType: commentCheckedValues[0],
          fscId: selectedTrimValue.value,
          canvas: rawDrawOnSlide,
          projectId: projectID,
          versionId: version,
          commentDetail: {
            user: userData?.fname + ' ' + userData?.lname,
            createdDate: '5/7/2022',
            content: {
              selectedTrim: selectedTrimValue.name,
              selectedCameraControl: selectedCameraControl.title,
              message: addNewComment,
              attachment: imageURL
            }
          },
          index: null,
          message: addNewComment
        })
      )
      dispatch(
        saveProjectComments({
          projectID,
          newComment: {
            id: nanoid(5),
            exteriorColorId: selectedExterior.value,
            cameraControlId: selectedCameraControl.value,
            interiorId: selectedInterior.value,
            fscId: selectedTrimValue.value,
            canvas: rawDrawOnSlide,
            projectId: projectID,
            versionId: version,
            commentType: commentCheckedValues[0],
            commentDetail: {
              user: userData?.fname + ' ' + userData?.lname,
              createdDate: '5/7/2022',
              content: {
                selectedTrim: selectedTrimValue.name,
                selectedCameraControl: selectedCameraControl.title,
                message: addNewComment,
                attachment: imageURL
              }
            },
            index: null,
            message: addNewComment
          }
        })
      )
      dispatch(
        saveAsTicket({
          projectID,
          newComment: {
            title:
              commentType[selectedCameraControl.value.slice(0, 1)] +
              ' ' +
              commentTitle[commentCheckedValues[0]] +
              ' & ' +
              titleType[commentCheckedValues[0]],
            desc: addNewComment,
            reporter: '605cc9f04b9135006a9d0e16',
            assignee: '605cc9f04b9135006a9d0e16',
            project: 'DCT',
            attachment: imageURL
          }
        })
      )
      setTimeout(() => {
        props.setActiveTool(null)
        dispatch(
          screenshotMode({
            status: false,
            projectID,
            selectedCameraControlValue: selectedCameraControl.value
          })
        )
        dispatch(clickMenuPano('HIDE_MENU'))
        dispatch(setSpriteSelectedPano('DEFAULT'))
      }, 3000)
    }
  }

  const closeCommentText = () => {
    setCommentCheckedValues()
    setOpenCommentTextModal(false)
    dispatch(clickMenuPano('HIDE_MENU'))
    dispatch(setSpriteSelectedPano('DEFAULT'))
  }

  const dragHandler = (e) => {
    const pitch = pannellumRef.current.panorama.getPitch()
    const yaw = pannellumRef.current.panorama.getYaw()
    const hfov = pannellumRef.current.panorama.getHfov()
    // if (ui.isCommentCreating) {
    //   const mousePosition = pannellumRef.current.panorama.mouseEventToCoords(e)
    //   const config = pannellumRef.current.panorama.getConfig()
    //   dispatch(
    //     setCommentPosition({
    //       pitch: mousePosition[0],
    //       yaw: mousePosition[1]
    //     })
    //   )
    //   config.hotSpots[0].pitch = mousePosition[0]
    //   config.hotSpots[0].yaw = mousePosition[1]
    // }
    dispatch(changePanoViewPosition({ pitch, yaw, hfov }))
  }

  return (
    <div className='panoWrapper' style={{ height: '100%', width: '100%', backgroundColor: '#fff' }}>
      {pannellumLoading && (
        <Loading />
      )}
      <div
        style={{
          width: '100%',
          height: '100%',
          zIndex: pannellumLoading ? -1 : 1,
          opacity: pannellumLoading ? 0 : 1
        }}
      >
        <Pannellum
          id='pannellum-render'
          ref={pannellumRef}
          width='100%'
          height='100%'
          image={props.src}
          pitch={ui.viewPosition.pitch}
          yaw={ui.viewPosition.yaw}
          hfov={ui.viewPosition.hfov}
          maxHfov={110}
          onLoad={() => {
            setPannellumLoading(false)
          }}
          minHfov={20}
          autoLoad
          onMouseup={dragHandler}
          onTouchend={dragHandler}
          disableKeyboardCtrl
          backgroundColor={[255, 255, 255]}
        />
      </div>
      {props.showComments && (
        <div
          style={{
            position: 'absolute',
            bottom: 20,
            minWidth: 400,
            maxWidth: 400,
            left: 'calc(221px + 1rem)'
          }}
        >
          <div className='show-comment'>
            <Comments
              title='COMMENTS'
              details={<ItemsList list={props.filterComments} />}
            />
          </div>
        </div>
      )}
    </div>
  )
}

PanoView.propTypes = {
  src: propTypes.string,
  setActiveTool: propTypes.func,
  showComments: propTypes.bool,
  filterComments: propTypes.array
}

export default PanoView

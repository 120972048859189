export const downloadImage = async (url) => {
  async function toDataURL () {
    const blob = await fetch(url).then(res => res.blob())
    return URL.createObjectURL(blob)
  }
  const a = document.createElement('a')
  a.href = await toDataURL()
  a.download = `${url.split('.')[url.split('.').length - 1]}.png`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const SONATA = 'Sonata'
export const HYUNDAI = 'Hyundai'
export const GENESIS = 'Genesis'
export const SUBARU = 'Subaru'
export const ARROW = 'arrow'
export const CIRCLE = 'circle'
export const SQUARE = 'square'
export const DEVELOPMENT = 'development'
export const COLUMN_LIVE = 'live'
export const COLUMN_LATEST_VERSION = 'latestVersion'
export const HYUNDAI_THREE_D_LENGTH = 36
export const GENESIS_THREE_D_LENGTH = 72
export const SUBARU_THREE_D_LENGTH = 144
export const BASE_API_URL = 'http://localhost:5000/api/v1'
// auth
export const SIGNIN_URL = './signin'

export const commentTypes = [
  { label: 'Trim Comment', value: 'trim_comment' },
  { label: 'Color Comment', value: 'color_comment' },
  { label: 'Camera Comment', value: 'camera_comment' },
  { label: 'Config Comment', value: 'config_comment' }
]

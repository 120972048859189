import React from 'react'

import { Button } from '@material-ui/core'

import { LIVE_VIEW, PREVIEW_VIEW } from 'constants/configurations'

export const projectColumns = [
  {
    title: 'Project Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
    key: 'brand',
    render: (text) => <p style={{ textTransform: 'uppercase' }}>{text}</p>
  },
  {
    title: 'Year',
    dataIndex: 'year',
    key: 'year',
    align: 'center',
    render: (text) => <p className='text-center'>{text}</p>
  },
  {
    title: LIVE_VIEW,
    dataIndex: LIVE_VIEW,
    key: LIVE_VIEW,
    align: 'center',
    clickable: true,
    render: (live) => (
      <Button variant='text' color='primary'>
        {live.assetsVersion}
      </Button>
    )
  },
  {
    title: PREVIEW_VIEW,
    dataIndex: PREVIEW_VIEW,
    key: PREVIEW_VIEW,
    align: 'center',
    clickable: true,
    render: (preview) => (
      <Button variant='text' color='primary'>
        {preview.assetsVersion}
      </Button>
    )
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    align: 'center',
    render: (text) => <p className='text-center'>{text}</p>
  },
  {
    title: 'Last Updated',
    dataIndex: 'lastUpdated',
    key: 'lastUpdated',
    align: 'center',
    render: (text) => <p className='text-center'>{text}</p>
  }
]

import { sprites } from '../constants/ui'

export const convertToDataCanvas = (commentsOfCamera) => {
  const arrows = []
  const circes = []
  const squares = []
  commentsOfCamera?.forEach((item) => {
    if (item.canvas?.type === 'ARROW') {
      arrows.push(item.canvas)
    } else if (item.canvas?.type === 'CIRCE') {
      circes.push(item.canvas)
    } else if (item.canvas?.type === 'SQUARE') {
      squares.push(item.canvas)
    }
  })
  return {
    arrows,
    circes,
    squares
  }
}

export const convertToDataCanvasSelect = (commentsOfCamera) => {
  const arrows = []
  const arrows1 = []
  const circes1 = []
  const circes = []
  const squares = []
  const squares1 = []
  const commentArray = []
  if (commentsOfCamera.slice(-1)[0]) {
    const selectedComment = commentsOfCamera.slice(-1)[0]
    commentArray.push(selectedComment)
  }
  commentsOfCamera?.forEach((item) => {
    if (item.canvas?.type === 'ARROW') {
      arrows.push(item.canvas)
    } else if (item.canvas?.type === 'CIRCE') {
      circes.push(item.canvas)
    } else if (item.canvas?.type === 'SQUARE') {
      squares.push(item.canvas)
    }
  })
  commentArray?.forEach((item) => {
    if (item.canvas?.type === 'ARROW') {
      arrows1.push(item.canvas)
    } else if (item.canvas?.type === 'CIRCE') {
      circes1.push(item.canvas)
    } else if (item.canvas?.type === 'SQUARE') {
      squares1.push(item.canvas)
    }
  })
  return {
    arrows,
    arrows1,
    circes,
    circes1,
    squares,
    squares1
  }
}

export const getNewDataCanvas = (dataCanvas, canvasCurrent) => {
  const newWidth = canvasCurrent.width
  const newArrows = dataCanvas?.arrows
    ? dataCanvas.arrows.map((item) => {
      const ratioX = canvasCurrent.width / item.width
      const ratioY = canvasCurrent.height / item.height
      return {
        width: item.width * ratioX,
        height: item.height * ratioY,
        fromX: Math.floor(item.fromX * ratioX),
        fromY: Math.floor(item.fromY * ratioY),
        toX: Math.floor(item.toX * ratioX),
        toY: Math.floor(item.toY * ratioY)
      }
    })
    : []

  const newArrows1 = dataCanvas?.arrows1
    ? dataCanvas.arrows1.map((item) => {
      const ratioX = canvasCurrent.width / item.width
      const ratioY = canvasCurrent.height / item.height
      return {
        width: item.width * ratioX,
        height: item.height * ratioY,
        fromX: Math.floor(item.fromX * ratioX),
        fromY: Math.floor(item.fromY * ratioY),
        toX: Math.floor(item.toX * ratioX),
        toY: Math.floor(item.toY * ratioY)
      }
    })
    : []

  const newCirces = dataCanvas?.circes
    ? dataCanvas.circes.map((item) => {
      const ratioX = canvasCurrent.width / item.width
      const ratioY = canvasCurrent.height / item.height
      return {
        width: item.width * ratioX,
        height: item.height * ratioY,
        fromX: Math.floor(item.fromX * ratioX),
        fromY: Math.floor(item.fromY * ratioY),
        toX: Math.floor(item.toX * ratioX),
        toY: Math.floor(item.toY * ratioY)
      }
    })
    : []

  const newCirces1 = dataCanvas?.circes1
    ? dataCanvas.circes1.map((item) => {
      const ratioX = canvasCurrent.width / item.width
      const ratioY = canvasCurrent.height / item.height
      return {
        width: item.width * ratioX,
        height: item.height * ratioY,
        fromX: Math.floor(item.fromX * ratioX),
        fromY: Math.floor(item.fromY * ratioY),
        toX: Math.floor(item.toX * ratioX),
        toY: Math.floor(item.toY * ratioY)
      }
    })
    : []

  const newSquares = dataCanvas?.squares
    ? dataCanvas.squares.map((item) => {
      const ratioX = canvasCurrent.width / item.width
      const ratioY = canvasCurrent.height / item.height
      return {
        width: item.width * ratioX,
        height: item.height * ratioY,
        fromX: Math.floor(item.fromX * ratioX),
        fromY: Math.floor(item.fromY * ratioY),
        toX: Math.floor(item.toX * ratioX),
        toY: Math.floor(item.toY * ratioY)
      }
    })
    : []

  const newSquares1 = dataCanvas?.squares1
    ? dataCanvas.squares1.map((item) => {
      const ratioX = canvasCurrent.width / item.width
      const ratioY = canvasCurrent.height / item.height
      return {
        width: item.width * ratioX,
        height: item.height * ratioY,
        fromX: Math.floor(item.fromX * ratioX),
        fromY: Math.floor(item.fromY * ratioY),
        toX: Math.floor(item.toX * ratioX),
        toY: Math.floor(item.toY * ratioY)
      }
    })
    : []

  return {
    ...dataCanvas,
    width: newWidth,
    arrows: newArrows,
    circes: newCirces,
    squares: newSquares,
    arrows1: newArrows1,
    circes1: newCirces1,
    squares1: newSquares1
  }
}

export const checkTwoDataCanvasWidthEquals = (canvas1, canvas2) => {
  return Math.ceil(canvas1.width) === Math.ceil(canvas2.width)
}

export const getNewXYCmtImgOfArrow = (
  fromX,
  fromY,
  toX,
  toY,
  imgWidth,
  imgHeight,
  cavansWidth,
  cavansHeight
) => {
  // arrow Top Left to Bot Right
  if (toX >= fromX && toY >= fromY) {
    // cmt at Top Left
    if (fromX < imgWidth && fromY < imgHeight) {
      return {
        fromX: 0,
        fromY: 0
      }
    } else if (fromX < imgWidth) {
    // cmt at Left
      return {
        fromX: 0,
        fromY: fromY - imgHeight / 2
      }
    } else if (fromY < imgHeight) {
    // cmt at Top
      return {
        fromX: fromX - imgWidth / 2,
        fromY: 0
      }
    }
    return {
      fromX: fromX - imgWidth / 2,
      fromY: fromY - imgHeight / 2
    }
  } else if (toX >= fromX && toY <= fromY) {
  // arrow Bottom Left to Top Right
    if (fromX < imgWidth && cavansHeight - fromY < imgHeight) {
    // cmt at Bot Left
      return {
        fromX: 0,
        fromY: cavansHeight - imgHeight
      }
    } else if (fromX < imgWidth) {
    // cmt at Left
      return {
        fromX: 0,
        fromY: fromY - imgHeight / 2
      }
    } else if (cavansHeight - fromY < imgHeight) {
    // cmt at Bot
      return {
        fromX: fromX - imgWidth / 2,
        fromY: cavansHeight - imgHeight
      }
    }

    return {
      fromX: fromX - imgWidth / 2,
      fromY: fromY - imgHeight / 2
    }
  } else if (toX <= fromX && toY >= fromY) {
  // arrow Top Right to Bot Left
    if (cavansWidth - fromX < imgWidth && fromY < imgHeight) {
    // cmt at top left
      return {
        fromX: cavansWidth - imgWidth,
        fromY: 0
      }
    } else if (cavansWidth - fromX < imgWidth) {
    // cmt at left
      return {
        fromX: cavansWidth - imgWidth,
        fromY: fromY - imgHeight / 2
      }
    } else if (fromY < imgHeight) {
    // cmt at top
      return {
        fromX: fromX - imgWidth / 2,
        fromY: 0
      }
    }
    return {
      fromX: fromX - imgWidth / 2,
      fromY: fromY - imgHeight / 2
    }
  } else if (toX <= fromX && toY <= fromY) {
  // arrow Bot Right to Top Left
    if (cavansWidth - fromX < imgWidth && cavansHeight - fromY < imgHeight) {
    // cmt at bot left
      return {
        fromX: cavansWidth - imgWidth,
        fromY: cavansHeight - imgHeight
      }
    } else if (cavansWidth - fromX < imgWidth) {
    // cmt at left
      return {
        fromX: cavansWidth - imgWidth,
        fromY: fromY - imgHeight / 2
      }
    } else if (cavansHeight - fromY < imgHeight) {
    // cmt at bot
      return {
        fromX: fromX - imgWidth / 2,
        fromY: cavansHeight - imgHeight
      }
    }
    return {
      fromX: fromX - imgWidth / 2,
      fromY: fromY - imgHeight / 2
    }
  }
  return {
    fromX,
    fromY
  }
}

export const getXYcirce = (fromX, fromY, toX, toY) => {
  const width = toX >= fromX ? toX - fromX : fromX - toX
  const height = toY >= fromY ? toY - fromY : fromY - toY
  const x = toX >= fromX ? fromX + width / 2 : fromX - width / 2
  const y = toY >= fromY ? toY - height / 2 : toY + height / 2
  const radius =
    Math.sqrt(
      Math.abs(width) * Math.abs(width) + Math.abs(height) * Math.abs(height)
    ) / 2
  return {
    x,
    y,
    radius
  }
}

export const getNewXYCmtImgOfCirce = (fromX, fromY, toX, toY) => {
  const width = toX >= fromX ? toX - fromX : fromX - toX
  const height = toY >= fromY ? toY - fromY : fromY - toY
  const x = toX >= fromX ? fromX + width / 2 : fromX - width / 2
  const y = toY >= fromY ? toY - height / 2 : toY + height / 2
  const radius =
    Math.sqrt(
      Math.abs(width) * Math.abs(width) + Math.abs(height) * Math.abs(height)
    ) / 2
  return {
    fromX: x + radius, //* Math.cos(Math.PI / 4),
    fromY: y - radius / 2 //* Math.sin(Math.PI / 4)
  }
}

export const getXYsquare = (fromX, fromY, toX, toY) => {
  const width = toX >= fromX ? toX - fromX : fromX - toX
  const height = toY >= fromY ? toY - fromY : fromY - toY
  const x = toX >= fromX ? fromX : toX
  const y = toY >= fromY ? fromY : toY
  return {
    x,
    y,
    width,
    height
  }
}

export const getNewXYCmtImgOfSquare = (
  fromX,
  fromY,
  toX,
  toY,
  imgWidth,
  imgHeight
) => {
  const height = toY >= fromY ? toY - fromY : fromY - toY
  const x = toX >= fromX ? fromX + (toX - fromX) : toX + (fromX - toX)
  const y =
    toY >= fromY ? fromY + height / 2 - imgHeight / 2 : toY + height / 2 - imgHeight / 2

  return {
    fromX: x,
    fromY: y
  }
}

export const typeDraw = (whatDraw) => {
  if (whatDraw === 'ARROW_DRAW') {
    return 'ARROW'
  }
  if (whatDraw === 'CIRCE_DRAW') {
    return 'CIRCE'
  }
  if (whatDraw === 'SQUARE_DRAW') {
    return 'SQUARE'
  }
  return 'DEFAULT'
}

export const getNewRawDrawCanvas = (rawDraw, canvasCurrent) => {
  const ratioX = canvasCurrent.width / rawDraw.width
  const ratioY = canvasCurrent.height / rawDraw.height
  return {
    ...rawDraw,
    width: rawDraw.width * ratioX,
    height: rawDraw.height * ratioY,
    fromX: rawDraw.fromX * ratioX,
    fromY: rawDraw.fromY * ratioY,
    toX: rawDraw.toX * ratioX,
    toY: rawDraw.toY * ratioY
  }
}

export const typeToSpriteUrl = (type, isSelected) => {
  switch (type) {
    case 'ARROW':
      return !isSelected ? sprites.arrow : sprites.arrowYellow
    case 'CIRCE':
      return !isSelected ? sprites.circe : sprites.circeYellow
    case 'SQUARE':
      return !isSelected ? sprites.square : sprites.squareYellow
    default:
      return null
  }
}

export const typeSpriteByDataUrl = (dataURL) => {
  if (dataURL.includes('arrows-with-transparent')) {
    return 'ARROW'
  } else if (dataURL.includes('circe_normal_red')) {
    return 'CIRCE'
  } else if (dataURL.includes('square_red')) {
    return 'SQUARE'
  } else {
    return null
  }
}

import html2canvas from 'html2canvas'
import { getXYcirce, getXYsquare } from './panoViewFunctions'
import axios from 'axios'

export const uploadCommentImage = async (commentShape, projectID) => {
  const WIDTH = 1157
  const HEIGHT = 650
  const page = document.getElementById('page')
  const img = document.getElementById('sourceImg')
  const copyImg = img.cloneNode(true)
  // copyImg.setAttribute("src", sampleCarImg);
  copyImg.style.height = '100%'
  copyImg.style.width = '100%'
  const div = document.createElement('div')
  div.style.position = 'absolute'
  div.style.bottom = '10px'
  div.style.right = '10px'
  div.style.width = WIDTH + 'px'
  div.style.height = HEIGHT + 'px'
  div.style.zIndex = '-1'

  div.appendChild(copyImg)
  page.appendChild(div)

  const canvas = await html2canvas(div, {
    height: HEIGHT,
    width: WIDTH,
    background: '#fff',
    scale: 3,
    useCORS: true,
    allowTaint: false
  })
  const ctx = canvas.getContext('2d')
  if (commentShape.type === 'CIRCE') {
    ctx.strokeStyle = 'red'
    ctx.lineWidth = 2

    const circe = getXYcirce(
      commentShape.fromX,
      commentShape.fromY,
      commentShape.toX,
      commentShape.toY
    )
    ctx.beginPath()
    ctx.arc(circe.x, circe.y, circe.radius, 0, 2 * Math.PI)
    // const square = getXYsquare(fromX, fromY, toX, toY);
    // ctx.rect(square.x, square.y, square.width, square.height);
    ctx.stroke()
  } else if (commentShape.type === 'SQUARE') {
    ctx.strokeStyle = 'red'
    ctx.lineWidth = 2
    const square = getXYsquare(
      commentShape.fromX,
      commentShape.fromY,
      commentShape.toX,
      commentShape.toY
    )
    ctx.beginPath()
    ctx.rect(square.x, square.y, square.width, square.height)
    ctx.stroke()
  } else if (commentShape.type === 'ARROW') {
    const headlen = 10
    ctx.strokeStyle = 'red'
    ctx.lineWidth = 3
    const dx = commentShape.toX - commentShape.fromX
    const dy = commentShape.toY - commentShape.fromY
    const angle = Math.atan2(dy, dx)
    ctx.beginPath()
    ctx.moveTo(commentShape.fromX, commentShape.fromY)
    ctx.lineTo(commentShape.toX, commentShape.toY)
    ctx.lineTo(
      commentShape.toX - headlen * Math.cos(angle - Math.PI / 6),
      commentShape.toY - headlen * Math.sin(angle - Math.PI / 6)
    )
    ctx.moveTo(commentShape.toX, commentShape.toY)
    ctx.lineTo(
      commentShape.toX - headlen * Math.cos(angle + Math.PI / 6),
      commentShape.toY - headlen * Math.sin(angle + Math.PI / 6)
    )
    ctx.stroke()
  }
  const dataURL = canvas.toDataURL()
  const res = await fetch(dataURL)
  const blob = await res.blob()
  const file = new File([blob], 'comment', { type: 'image/png' })
  const formData = new FormData()
  formData.append('file', file)
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  const uploadImage = await axios.post(
    `https://img-api-test.thedigigi.net/v1/file/${projectID}`,
    formData,
    config
  )
  if (uploadImage?.data?.status) {
    return `https://img-api-test.thedigigi.net/v1/file/${projectID}/${uploadImage?.data?.file_id}`
  } else {
    return false
  }
}

import React from 'react'
import clsx from 'clsx'
import propTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Pagination from 'components/common/Pagination'
import 'components/common/Table/Table.scss'

function sortTable (n) {
  let table; let rows; let switching; let i; let x; let y; let shouldSwitch; let dir; let switchcount = 0
  table = document.getElementById('projects_table')
  switching = true
  dir = 'asc'
  while (switching) {
    switching = false
    rows = table.rows
    for (i = 1; i < (rows.length - 1); i++) {
      shouldSwitch = false
      x = rows[i].getElementsByTagName('TD')[n]
      y = rows[i + 1].getElementsByTagName('TD')[n]
      if (dir === 'asc') {
        if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
          shouldSwitch = true
          break
        }
      } else if (dir === 'desc') {
        if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
          shouldSwitch = true
          break
        }
      }
    }
    if (shouldSwitch) {
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i])
      switching = true
      switchcount++
    } else {
      if (switchcount === 0 && dir === 'asc') {
        dir = 'desc'
        switching = true
      }
    }
  }
}

const MyTable = (props) => {
  const {
    dataSource,
    columns,
    hover,
    className,
    onAction,
    hoverClickDisableAtRow,
    total,
    page,
    limit
  } = props
  const { columnName, value } = hoverClickDisableAtRow || {}
  const checkColumnName = columnName.split('.')

  return (
    <div className={clsx('commonTableWrapper', className && className)}>
      <TableContainer>
        <Table id='projects_table' aria-label='projects table' className='table'>
          <TableHead>
            <TableRow>
              {columns.map((item, idx) => (
                <TableCell key={`${item.key}`} align={item.align} onClick={() => { sortTable(idx) }}>
                  {item.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource.map((row, index) => {
              return (
                <TableRow
                  key={row.id || index}
                  className={clsx(
                    hover && 'row-hover',
                    hoverClickDisableAtRow &&
                      row[checkColumnName[0]][checkColumnName[1]] === value &&
                      'row-hover-disable'
                  )}
                >
                  {columns.map((itemRow, index) => (
                    <TableCell
                      key={itemRow.key + index}
                      align={columns[index]?.alignItems}
                      onClick={
                        onAction && columns[index]?.clickable
                          ? (e) =>
                              hoverClickDisableAtRow
                                ? row[checkColumnName[0]][
                                  checkColumnName[1]
                                ] !== value &&
                                  onAction(e, columns[index]?.dataIndex, row)
                                : onAction(e, columns[index]?.dataIndex, row)
                          : null
                      }
                    >
                      {itemRow.render ? itemRow.render(row[itemRow.dataIndex], row) : row[itemRow.dataIndex]}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination total={total} page={page} limit={limit} />
    </div>
  )
}

MyTable.propTypes = {
  dataSource: propTypes.array,
  columns: propTypes.array,
  hover: propTypes.bool,
  className: propTypes.string,
  onAction: propTypes.func,
  hoverClickDisableAtRow: propTypes.any
}

export default MyTable

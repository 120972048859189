import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  customCanvasMatchCurrentScreen,
  saveRawDraw,
  changeIsComment,
  clickMenu
} from 'redux/reducers/canvas/canvasSlice'
import 'components/Slider/Slider.scss'
import { TextareaAutosize, Chip } from '@material-ui/core'
import {
  convertToDataCanvas,
  getNewDataCanvas,
  checkTwoDataCanvasWidthEquals,
  getNewXYCmtImgOfArrow,
  getXYcirce,
  getNewXYCmtImgOfCirce,
  getXYsquare,
  getNewXYCmtImgOfSquare,
  typeDraw,
  getNewRawDrawCanvas,
  convertToDataCanvasSelect
} from 'utils/panoViewFunctions'
import { commentTypes } from 'constants/constants'
import cmt from 'assets/icons/cmt.png'
import useWindowSize from 'hooks/useWindowSize'
import { DEFAULT, slice } from 'constants/ui'
import ModalDisplay from 'components/common/Modal/Modal'
import Button from 'components/common/Button/Button'
import MyCheckBox from 'components/common/CheckBox/CheckBox'
import {
  saveProjectComments,
  setSaveComments,
  saveAsTicket,
  setCameraControl
  // fetchProjectComments,
} from 'redux/reducers/ui/uiSlice'
import { nanoid } from 'nanoid'
import { uploadCommentImage } from 'utils/uploadCommentImage'
import propTypes from 'prop-types'
import useAspectRatio from '../../hooks/useAspectRatio'

const {
  canvas: {
    draw: { ARROW_DRAW, CIRCE_DRAW, SQUARE_DRAW, SHOW_DRAW }
  }
} = slice

const Slider = ({ imageSource, setActiveTool, frameNum }) => {
  const dispatch = useDispatch()
  const canvasSlider = useSelector((state) => state.canvas.presentation.slider)
  const indexDrawOnSlice = useSelector(
    (state) => state.canvas.presentation.slider.canvas.index
  )
  const rawDrawOnSlide = useSelector(
    (state) => state.canvas.presentation.slider.canvas.rawDraw
  )
  const [isDrawing, setIsDrawing] = useState(false)
  const canvasRef = useRef(null)
  const contextRef = useRef(null)
  const imgRef = useRef(null)
  const cmtImgRef = useRef(null)
  const imageContainer = useRef(null)
  useAspectRatio(imageContainer, imageSource, 'sliderPhotos')
  const [openCommentOptionsModal, setOpenCommentOptionsModal] = useState(false)
  const [openCommentTextModal, setOpenCommentTextModal] = useState(false)
  const [commentCheckedValues, setCommentCheckedValues] = useState()
  const [addNewComment, setAddNewComment] = useState('')
  const [start, setStart] = useState({ x: 0, y: 0, width: 0, height: 0 })
  const [selectedCameraControl, setSelectedCameraControl] = useState({
    data: [],
    value: ''
  })
  const [widthScreen] = useWindowSize()
  const [userData, setUserData] = useState({})
  const dataCanvas = canvasSlider.canvas.data
  const {
    configre: {
      cameraControls,
      selectedTrimValue,
      selectedExterior,
      selectedInterior,
      showComments
    },
    saveComments
  } = useSelector((state) => state.ui)

  const selectedProject = useSelector((state) => state.projects.selectedProject)

  const onLoadCanvas = async (status) => {
    if (!imgRef?.current?.offsetWidth) return
    const img = document.getElementById('sourceImg')
    const cmt2 = document.getElementById('icon')
    const canvas = document.getElementById('myCanvas')
    const ctx = canvas.getContext('2d')
    canvasRef.current = canvas
    cmtImgRef.current = cmt2
    contextRef.current = ctx
    if (status === 'FIRST') {
      canvas.style.width = imgRef.current.offsetWidth
      canvas.style.height =
        imgRef?.current?.offsetHeight > 0
          ? imgRef?.current?.offsetHeight
          : +imgRef.current.offsetWidth / (img.naturalWidth / img.naturalHeight)
      img.addEventListener(
        'load',
        () => {
          const newDataCanvas = getNewDataCanvas(dataCanvas, {
            width: imgRef.current.offsetWidth,
            height:
              imgRef?.current?.offsetHeight > 0
                ? imgRef?.current?.offsetHeight
                : +imgRef.current.offsetWidth /
                  (img.naturalWidth / img.naturalHeight)
          })
          if (!checkTwoDataCanvasWidthEquals(dataCanvas, newDataCanvas)) {
            // match current screen
            dispatch(customCanvasMatchCurrentScreen(newDataCanvas))
            return
          }
          createCanvas(imgRef.current, contextRef.current, canvasRef.current)
        },
        false
      )
    } else {
      const newDataCanvas = getNewDataCanvas(dataCanvas, {
        width: imgRef?.current?.offsetWidth,
        height:
          imgRef?.current?.offsetHeight > 0
            ? imgRef?.current?.offsetHeight
            : imgRef.current.offsetWidth /
              (img.naturalWidth / img.naturalHeight)
      })
      if (!checkTwoDataCanvasWidthEquals(dataCanvas, newDataCanvas)) {
        // match current screen
        dispatch(customCanvasMatchCurrentScreen(newDataCanvas))
        return
      }
      createCanvas(img, ctx, canvas)
    }
  }

  const createCanvas = (img, ctx, canvas) => {
    const dWidth = img.offsetWidth
    const ratio = img.naturalWidth / img.naturalHeight
    const Rwidth = dWidth
    const Rheight = Rwidth / ratio
    canvas.width = dWidth // dWidth;
    canvas.height = Rheight // dHeight;
    setStart({ ...start, width: dWidth, height: Rheight })

    ctx.drawImage(img, 0, 0, dWidth, Rheight)

    if (canvasSlider.canvas.draw !== DEFAULT) {
      drawRawCanvas(
        getNewRawDrawCanvas(canvasSlider.canvas.rawDraw, {
          width: img.offsetWidth,
          height: img.offsetHeight
        })
      )
      drawCanvas(dataCanvas)
    }
  }

  const drawImageFull = (ctx, img, canvas) => {
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
  }

  const drawCanvas = (data) => {
    data?.arrows?.forEach((arrow) => {
      canvas_arrow(arrow.fromX, arrow.fromY, arrow.toX, arrow.toY)
    })
    data?.circes?.forEach((arrow) => {
      canvas_circe(arrow.fromX, arrow.fromY, arrow.toX, arrow.toY)
    })
    data?.squares?.forEach((arrow) => {
      canvas_square(arrow.fromX, arrow.fromY, arrow.toX, arrow.toY)
    })
    data?.arrows1?.forEach((arrow) => {
      canvas_arrow1(arrow.fromX, arrow.fromY, arrow.toX, arrow.toY)
    })
    data?.circes1?.forEach((arrow) => {
      canvas_circe1(arrow.fromX, arrow.fromY, arrow.toX, arrow.toY)
    })
    data?.squares1?.forEach((arrow) => {
      canvas_square1(arrow.fromX, arrow.fromY, arrow.toX, arrow.toY)
    })
  }

  const drawRawCanvas = (rawDraw) => {
    const whatDraw = rawDraw.type + '_DRAW'
    if (whatDraw === ARROW_DRAW) {
      canvas_arrow(rawDraw.fromX, rawDraw.fromY, rawDraw.toX, rawDraw.toY)
    }
    if (whatDraw === CIRCE_DRAW) {
      canvas_circe(rawDraw.fromX, rawDraw.fromY, rawDraw.toX, rawDraw.toY)
    }
    if (whatDraw === SQUARE_DRAW) {
      canvas_square(rawDraw.fromX, rawDraw.fromY, rawDraw.toX, rawDraw.toY)
    }
  }

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent
    setStart({ ...start, x: offsetX, y: offsetY })
    setIsDrawing(true)
    dispatch(changeIsComment(false))
  }

  const finishDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent
    const whatDraw = canvasSlider.canvas.draw
    if (isDrawing) {
      dispatch(
        saveRawDraw({
          width: start.width,
          height: start.height,
          fromX: start.x,
          fromY: start.y,
          toX: offsetX,
          toY: offsetY,
          type: typeDraw(whatDraw)
        })
      )
      dispatch(changeIsComment(true))
      setOpenCommentOptionsModal(true)
    }
    setIsDrawing(false)
  }

  const draw = ({ nativeEvent }) => {
    const whatDraw = canvasSlider.canvas.draw
    if (isDrawing) {
      const { offsetX, offsetY } = nativeEvent
      canvas_clear()
      drawImageFull(contextRef.current, imgRef.current, canvasRef.current)
      drawCanvas(dataCanvas)
      if (whatDraw === ARROW_DRAW) {
        canvas_arrow(start.x, start.y, offsetX, offsetY)
      }
      if (whatDraw === CIRCE_DRAW) {
        canvas_circe(start.x, start.y, offsetX, offsetY)
      }
      if (whatDraw === SQUARE_DRAW) {
        canvas_square(start.x, start.y, offsetX, offsetY)
      }
    }
  }

  // eslint-disable-next-line camelcase
  const canvas_clear = () => {
    const ctx = contextRef.current
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
  }

  // eslint-disable-next-line camelcase
  const canvas_arrow = (fromX, fromY, toX, toY) => {
    const headlen = 10 // length of head in pixels
    const dx = toX - fromX
    const dy = toY - fromY
    const angle = Math.atan2(dy, dx)
    const ctx = contextRef.current
    ctx.strokeStyle = 'red' || 'black'
    // ctx.lineCap = "round";
    ctx.lineWidth = 3
    const newXYcmtImg = getNewXYCmtImgOfArrow(
      fromX,
      fromY,
      toX,
      toY,
      cmtImgRef.current.width,
      cmtImgRef.current.height,
      canvasRef.current.width,
      canvasRef.current.height
    )

    ctx.drawImage(
      cmtImgRef.current,
      newXYcmtImg.fromX,
      newXYcmtImg.fromY,
      cmtImgRef.current.width,
      cmtImgRef.current.height
    )

    ctx.beginPath()
    ctx.moveTo(fromX, fromY)
    ctx.lineTo(toX, toY)
    ctx.lineTo(
      toX - headlen * Math.cos(angle - Math.PI / 6),
      toY - headlen * Math.sin(angle - Math.PI / 6)
    )
    ctx.moveTo(toX, toY)
    ctx.lineTo(
      toX - headlen * Math.cos(angle + Math.PI / 6),
      toY - headlen * Math.sin(angle + Math.PI / 6)
    )
    ctx.stroke()
  }

  // eslint-disable-next-line camelcase
  const canvas_arrow1 = (fromX, fromY, toX, toY) => {
    const headlen = 10 // length of head in pixels
    const dx = toX - fromX
    const dy = toY - fromY
    const angle = Math.atan2(dy, dx)
    const ctx = contextRef.current
    ctx.strokeStyle = 'yellow' || 'black'
    // ctx.lineCap = "round";
    ctx.lineWidth = 3
    const newXYcmtImg = getNewXYCmtImgOfArrow(
      fromX,
      fromY,
      toX,
      toY,
      cmtImgRef.current.width,
      cmtImgRef.current.height,
      canvasRef.current.width,
      canvasRef.current.height
    )

    ctx.drawImage(
      cmtImgRef.current,
      newXYcmtImg.fromX,
      newXYcmtImg.fromY,
      cmtImgRef.current.width,
      cmtImgRef.current.height
    )

    ctx.beginPath()
    ctx.moveTo(fromX, fromY)
    ctx.lineTo(toX, toY)
    ctx.lineTo(
      toX - headlen * Math.cos(angle - Math.PI / 6),
      toY - headlen * Math.sin(angle - Math.PI / 6)
    )
    ctx.moveTo(toX, toY)
    ctx.lineTo(
      toX - headlen * Math.cos(angle + Math.PI / 6),
      toY - headlen * Math.sin(angle + Math.PI / 6)
    )
    ctx.stroke()
  }

  // eslint-disable-next-line camelcase
  const canvas_circe = (fromX, fromY, toX, toY) => {
    const ctx = contextRef.current
    ctx.strokeStyle = 'red' || 'black'
    ctx.lineWidth = 2
    const newXYcmtImg = getNewXYCmtImgOfCirce(
      fromX,
      fromY,
      toX,
      toY,
      cmtImgRef.current.width,
      cmtImgRef.current.height,
      canvasRef.current.width,
      canvasRef.current.height
    )
    ctx.drawImage(
      cmtImgRef.current,
      newXYcmtImg.fromX,
      newXYcmtImg.fromY,
      cmtImgRef.current.width,
      cmtImgRef.current.height
    )
    const circe = getXYcirce(fromX, fromY, toX, toY)
    ctx.beginPath()
    ctx.arc(circe.x, circe.y, circe.radius, 0, 2 * Math.PI)
    // const square = getXYsquare(fromX, fromY, toX, toY);
    // ctx.rect(square.x, square.y, square.width, square.height);
    ctx.stroke()
  }

  // eslint-disable-next-line camelcase
  const canvas_circe1 = (fromX, fromY, toX, toY) => {
    const ctx = contextRef.current
    ctx.strokeStyle = 'yellow' || 'black'
    ctx.lineWidth = 2
    const newXYcmtImg = getNewXYCmtImgOfCirce(
      fromX,
      fromY,
      toX,
      toY,
      cmtImgRef.current.width,
      cmtImgRef.current.height,
      canvasRef.current.width,
      canvasRef.current.height
    )
    ctx.drawImage(
      cmtImgRef.current,
      newXYcmtImg.fromX,
      newXYcmtImg.fromY,
      cmtImgRef.current.width,
      cmtImgRef.current.height
    )
    const circe = getXYcirce(fromX, fromY, toX, toY)
    ctx.beginPath()
    ctx.arc(circe.x, circe.y, circe.radius, 0, 2 * Math.PI)
    // const square = getXYsquare(fromX, fromY, toX, toY);
    // ctx.rect(square.x, square.y, square.width, square.height);
    ctx.stroke()
  }

  // eslint-disable-next-line camelcase
  const canvas_square = (fromX, fromY, toX, toY) => {
    const ctx = contextRef.current
    ctx.strokeStyle = 'red' || 'black'
    ctx.lineWidth = 2
    const newXYcmtImg = getNewXYCmtImgOfSquare(
      fromX,
      fromY,
      toX,
      toY,
      cmtImgRef.current.width,
      cmtImgRef.current.height,
      canvasRef.current.width,
      canvasRef.current.height
    )
    ctx.drawImage(
      cmtImgRef.current,
      newXYcmtImg.fromX,
      newXYcmtImg.fromY,
      cmtImgRef.current.width,
      cmtImgRef.current.height
    )
    const square = getXYsquare(fromX, fromY, toX, toY)
    ctx.beginPath()
    ctx.rect(square.x, square.y, square.width, square.height)
    ctx.stroke()
  }
  // eslint-disable-next-line camelcase
  const canvas_square1 = (fromX, fromY, toX, toY) => {
    const ctx = contextRef.current
    ctx.strokeStyle = 'yellow' || 'black'
    ctx.lineWidth = 2
    const newXYcmtImg = getNewXYCmtImgOfSquare(
      fromX,
      fromY,
      toX,
      toY,
      cmtImgRef.current.width,
      cmtImgRef.current.height,
      canvasRef.current.width,
      canvasRef.current.height
    )
    ctx.drawImage(
      cmtImgRef.current,
      newXYcmtImg.fromX,
      newXYcmtImg.fromY,
      cmtImgRef.current.width,
      cmtImgRef.current.height
    )
    const square = getXYsquare(fromX, fromY, toX, toY)
    ctx.beginPath()
    ctx.rect(square.x, square.y, square.width, square.height)
    ctx.stroke()
  }

  useMemo(() => {
    if (cameraControls && Array.isArray(cameraControls.data)) {
      setCameraControl(
        cameraControls.data.find(({ value }) => value === cameraControls.value)
      )
    }
  }, [cameraControls])

  useEffect(() => {
    setUserData(JSON.parse(window.localStorage.getItem('userData')))
  }, [])

  useEffect(() => {
    onLoadCanvas('FIRST')
  }, [
    canvasSlider,
    imgRef?.current?.offsetHeight,
    imgRef?.current?.offsetWidth
  ])

  useEffect(() => {
    const { id: projectID } = selectedProject || {
      version: '',
      id: ''
    }

    setTimeout(() => {}, 2000)

    // find comment on selected project
    let filterProjectComment = saveComments.filter(
      (projectId) => projectId.projectId === projectID
    )
    if (frameNum > -1) {
      filterProjectComment = filterProjectComment.filter(
        (projectId) => projectId.index === frameNum
      )
    }

    // find Color Comment
    const filterColorComments = filterProjectComment.filter(
      (filterItem) =>
        filterItem.exteriorColorId === selectedExterior.value &&
        filterItem.cameraControlId === selectedCameraControl.value &&
        filterItem.commentType === 'color_comment'
    )

    // find Camera Comment
    const filterCameraComment = filterProjectComment.filter(
      (filterItem) =>
        filterItem.cameraControlId === selectedCameraControl.value &&
        filterItem.commentType === 'camera_comment'
    )

    // find Config Comment
    const filterConfigComment = filterProjectComment.filter(
      (filterItem) =>
        filterItem.cameraControlId === selectedCameraControl.value &&
        filterItem.fscId === selectedTrimValue.value &&
        filterItem.exteriorColorId === selectedExterior.value &&
        filterItem.commentType === 'config_comment'
    )

    // find Trim Comment
    const filterTrimComments = filterProjectComment.filter(
      (filterItem) =>
        filterItem.fscId === selectedTrimValue.value &&
        filterItem.cameraControlId === selectedCameraControl.value &&
        filterItem.commentType === 'trim_comment'
    )
    if (cameraControls.id) {
      dispatch(
        customCanvasMatchCurrentScreen(
          convertToDataCanvasSelect(
            filterTrimComments.concat(
              filterColorComments,
              filterCameraComment,
              filterConfigComment
            )
          )
        )
      )
    } else {
      dispatch(
        customCanvasMatchCurrentScreen(
          convertToDataCanvas(
            filterTrimComments.concat(
              filterColorComments,
              filterCameraComment,
              filterConfigComment
            )
          )
        )
      )
    }
  }, [
    saveComments,
    widthScreen,
    selectedCameraControl.value,
    selectedProject,
    selectedExterior.value,
    selectedInterior.value,
    selectedTrimValue.value,
    showComments
  ])

  useEffect(() => {
    onLoadCanvas()
  }, [
    canvasSlider.canvas.draw,
    canvasSlider.canvas.data,
    canvasSlider.canvas.rawDraw,
    imgRef?.current?.offsetHeight
  ])

  const onSelectedCommentOption = () => {
    setOpenCommentOptionsModal(false)
    setOpenCommentTextModal(true)
  }

  const closeCommentText = () => {
    setCommentCheckedValues()
    setOpenCommentTextModal(false)
  }

  const onChangeComment = (e) => {
    const currentText = e.target.value
    setAddNewComment(currentText)
  }

  const commentTitle = {
    trim_comment: 'Trim comment',
    color_comment: 'Color comment',
    camera_comment: 'Camera comment',
    config_comment: 'Config comment'
  }

  const titleType = {
    trim_comment: selectedTrimValue.value,
    color_comment: selectedExterior.value,
    camera_comment: selectedCameraControl.title,
    config_comment:
      'Trim:' +
      selectedTrimValue.value +
      ' ' +
      'Color:' +
      selectedExterior.value +
      ' ' +
      'View:' +
      ' ' +
      selectedCameraControl.title
  }

  const commentType = {
    2: 'Exterior Comment',
    1: 'Interior Comment'
  }

  const onCommentComplete = async () => {
    const { version, id: projectID } = selectedProject || {
      version: '',
      id: ''
    }
    if (selectedProject?.id && selectedProject?.version) {
      dispatch(clickMenu('SHOW_MENU'))
      setOpenCommentTextModal(false)
      setActiveTool(null)
      const imageURL = await uploadCommentImage(rawDrawOnSlide, projectID)
      dispatch(
        setSaveComments({
          id: nanoid(5),
          exteriorColorId: selectedExterior.value,
          cameraControlId: selectedCameraControl.value,
          interiorId: selectedInterior.value,
          commentType: commentCheckedValues[0],
          fscId: selectedTrimValue.value,
          canvas: rawDrawOnSlide,
          projectId: projectID,
          versionId: version,
          commentDetail: {
            user: userData?.fname + ' ' + userData?.lname,
            createdDate: '5/7/2022',
            content: {
              selectedTrim: selectedTrimValue.name,
              selectedCameraControl: selectedCameraControl.title,
              message: addNewComment,
              attachment: imageURL
            }
          },
          index: frameNum > -1 ? frameNum : indexDrawOnSlice,
          message: addNewComment
        })
      )
      dispatch(
        saveProjectComments({
          projectID,
          newComment: {
            id: nanoid(5),
            exteriorColorId: selectedExterior.value,
            cameraControlId: selectedCameraControl.value,
            interiorId: selectedInterior.value,
            fscId: selectedTrimValue.value,
            canvas: rawDrawOnSlide,
            projectId: projectID,
            versionId: version,
            commentType: commentCheckedValues[0],
            commentDetail: {
              user: userData?.fname + ' ' + userData?.lname,
              createdDate: '5/7/2022',
              content: {
                selectedTrim: selectedTrimValue.name,
                selectedCameraControl: selectedCameraControl.title,
                message: addNewComment,
                attachment: imageURL
              }
            },
            index: frameNum > -1 ? frameNum : indexDrawOnSlice,
            message: addNewComment
          }
        })
      )
      dispatch(
        saveAsTicket({
          projectID,
          newComment: {
            title:
              commentType[selectedCameraControl.value.slice(0, 1)] +
              ' ' +
              commentTitle[commentCheckedValues[0]] +
              ' & ' +
              titleType[commentCheckedValues[0]],
            desc: addNewComment,
            reporter: '605cc9f04b9135006a9d0e16',
            assignee: '605cc9f04b9135006a9d0e16',
            project: 'DCT',
            attachment: imageURL
          }
        })
      )
    }
  }

  const onCommentModalClose = () => {
    setOpenCommentOptionsModal(false)
  }

  return (
    <div className='slider' id='page' ref={imageContainer}>
      <div className='slider_comment-icon'>
        <img id='icon' src={cmt} alt='comment-icon' />
      </div>
      <div className='slider_photos'>
        <div className='imageContainer' id='sliderPhotos'>
          <div>
            <img
              id='sourceImg'
              src={imageSource}
              ref={imgRef}
              alt=''
              className='slider_photo'
            />
          </div>
          <div>
            <canvas
              id='myCanvas'
              width={100}
              height={50}
              className={`slider_photo  ${
                canvasSlider.canvas.draw !== DEFAULT &&
                canvasSlider.canvas.draw !== SHOW_DRAW &&
                'slider_photo-drawing'
              }`}
              onMouseDown={
                canvasSlider.canvas.draw !== DEFAULT &&
                canvasSlider.canvas.draw !== SHOW_DRAW
                  ? startDrawing
                  : () => {}
              }
              onMouseUp={
                canvasSlider.canvas.draw !== DEFAULT &&
                canvasSlider.canvas.draw !== SHOW_DRAW
                  ? finishDrawing
                  : () => {}
              }
              onMouseMove={
                canvasSlider.canvas.draw !== DEFAULT &&
                canvasSlider.canvas.draw !== SHOW_DRAW
                  ? draw
                  : () => {}
              }
            >
              Your browser does not support the HTML5 canvas tag.
            </canvas>
          </div>
        </div>
      </div>
      <ModalDisplay
        modalBody={
          <div className='slider_comment'>
            <div className='slider_comment_heading'>COMMENT TYPE</div>

            <div className='slider_comment_comment-list'>
              <MyCheckBox
                checkBoxList={commentTypes}
                checkedValues={setCommentCheckedValues}
              />
            </div>

            <div className='next-wrapper'>
              <Button
                className='next-button'
                onClick={onSelectedCommentOption}
                toolTipText='Please select atleast one.'
                toolTipOpen={
                  !(commentCheckedValues && commentCheckedValues.length > 0)
                }
                toolTipArrow
                disabled={
                  !(commentCheckedValues && commentCheckedValues.length > 0)
                }
              >
                Next
              </Button>
            </div>
          </div>
        }
        openModal={openCommentOptionsModal}
        closeModal={onCommentModalClose}
      />
      <ModalDisplay
        modalBody={
          <div className='slider_comment'>
            <div className='slider_comment_heading'>Add Note</div>

            <div className='slider_comment_comment-text'>
              <div className='chip'>
                Config:
                <Chip
                  size='small'
                  label={selectedTrimValue.name}
                  color='primary'
                  variant='outlined'
                />
                <Chip
                  size='small'
                  color='primary'
                  label={selectedCameraControl.title}
                  variant='outlined'
                />
              </div>
              Note:
              <TextareaAutosize
                aria-label='comment'
                minRows={6}
                placeholder=''
                onChange={onChangeComment}
                style={{ width: '100%' }}
                autoFocus
              />
            </div>

            <div className='next-wrapper'>
              <Button
                className='next-button'
                onClick={onCommentComplete}
                toolTipText='Please write few comments.'
                toolTipOpen={
                  !(commentCheckedValues && commentCheckedValues.length > 0)
                }
                toolTipArrow
                disabled={
                  !(commentCheckedValues && commentCheckedValues.length > 0)
                }
              >
                COMPLETE
              </Button>
            </div>
          </div>
        }
        openModal={openCommentTextModal}
        closeModal={closeCommentText}
      />
    </div>
  )
}

Slider.propTypes = {
  imageSource: propTypes.string,
  setActiveTool: propTypes.func,
  frameNum: propTypes.number
}

export default Slider

import axios from 'axios'
import * as Sentry from '@sentry/browser'

const API = process.env.REACT_APP_CONFIG_URL
const APP_ID = process.env.REACT_APP_CONFIG_APP_ID

export async function listProjectsAPI (params) {
  return axios({
    method: 'get',
    url: `${API}/v1/search/${APP_ID}/projects`,
    headers: {},
    withCredentials: true,
    params
  })
    .then((resp) => resp.data)
    .then((resp) => {
      const project = []
      if (resp && resp.data) {
        resp.data.forEach((item) => {
          const obj = item.data
          obj.app_id = item.app_id
          obj.obj_id = item.obj_id
          obj.path = item.path
          project.push(obj)
        })
        return { project, total: resp.total }
      }
      return { project, total: resp.total }
    })
    .catch((err) => {
      if (err && err.response?.status !== 401) {
        Sentry.captureException(err)
      }
    })
}

export async function getProjectByDomainAPI (domain) {
  return axios({
    method: 'get',
    url: `${API}/v1/search/${APP_ID}/projects/${domain}`,
    headers: {},
    withCredentials: true
  })
    .then((resp) => resp.data)
    .then((resp) => {
      const project = []
      if (resp && resp.data) {
        resp.data.forEach((item) => {
          const obj = item.data
          obj.app_id = item.app_id
          obj.obj_id = item.obj_id
          obj.path = item.path
          project.push(obj)
        })
        return project
      }
      return project
    })
    .catch((err) => {
      if (err && err.response.status !== 401) {
        Sentry.captureException(err)
      }
    })
}

export async function getProjectByURLAPI () {
  const currentURL = window.location.hostname
  return axios({
    method: 'get',
    url: `${API}/v1/search/${APP_ID}/projects/${currentURL}`,
    headers: {},
    withCredentials: true
  })
    .then((resp) => resp?.data?.data)
    .catch((err) => {
      if (err.response?.status !== 401) {
        Sentry.captureException(err)
      }
    })
}

export async function postProjectsAPI (obj) {
  return axios({
    method: 'post',
    url: `${API}/v1/data/${APP_ID}`,
    headers: {},
    data: obj,
    withCredentials: true
  })
    .then((resp) => resp.data)
    .catch((err) => {
      if (err && err.response.status !== 401) {
        Sentry.captureException(err)
      }
    })
}

export async function getProjectsAPI (objId) {
  return axios({
    method: 'get',
    url: `${API}/v1/data/${APP_ID}/${objId}`,
    headers: {},
    withCredentials: true
  })
    .then((resp) => resp.data)
    .catch((err) => {
      if (err && err.response.status !== 401) {
        Sentry.captureException(err)
      }
    })
}

export async function putProjectsAPI (obj) {
  return axios({
    method: 'put',
    url: `${API}/v1/data/${APP_ID}/${obj.obj_id}`,
    headers: {},
    data: obj,
    withCredentials: true
  })
    .then((resp) => resp.data)
    .catch((err) => {
      if (err && err.response.status !== 401) {
        Sentry.captureException(err)
      }
    })
}

export async function getAdminConfigAPI () {
  return axios({
    method: 'get',
    url: `${API}/v1/search/${APP_ID}/admin/config`,
    headers: {},
    withCredentials: true
  })
    .then((resp) => resp.data)
    .catch((err) => {
      if (err && err.response.status !== 401) {
        Sentry.captureException(err)
      }
    })
}

import React, { useState } from 'react'
import getThreeDLenght from '../../utils/getThreeDLength'
import { downloadImage } from '../../utils/downloadImage'
import { useSelector } from 'react-redux'
import { DownloadIcon } from '../icons/icons'
import { CircularProgress } from '@material-ui/core'

function DownloadButton (props) {
  const {
    slider: { data },
    configre: {
      cameraControls
    }
  } = useSelector((state) => state.ui)
  const { selectedProject: { brand } } = useSelector(
    (state) => state.projects
  )
  const [loading, setLoading] = useState(false)

  const handleDownload = async () => {
    setLoading(true)
    const allImages = data.reduce(
      (prev, current) => prev.concat(current.images),
      []
    )
    const { title } = cameraControls.data.filter(
      (cam) => cam.value === cameraControls.value
    )[0]
    const threeDLength = getThreeDLenght(brand, cameraControls.value)
    const cameraIndex = allImages.findIndex((img) =>
      img.endsWith(cameraControls.value)
    )
    let imageUrl
    if (title.includes('360')) {
      imageUrl = allImages.slice(cameraIndex, cameraIndex + threeDLength)[cameraControls.frameNum]
    } else {
      imageUrl = allImages[cameraIndex]
    }
    if (imageUrl) {
      downloadImage(imageUrl).finally(() => setLoading(false))
    }
  }

  return (
    <div className='download-icon'>
      {
        loading ? <CircularProgress size={24} color='#fff' /> : <DownloadIcon onClick={handleDownload} />
      }
    </div>
  )
}

export default DownloadButton

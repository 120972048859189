import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { InfoIcon } from '../icons/icons'

import './styles.scss'

function InfoButton (props) {
  const [isDlgOpen, setDlgOpen] = useState(false)

  const { selectedProject: { info } } = useSelector(
    (state) => state.projects
  )

  const handleClick = (ev) => {
    setDlgOpen(true)
  }

  return (
    <>
      <InfoIcon className='info-btn' onClick={handleClick} />

      <Dialog
        open={isDlgOpen}
        onClose={ev => setDlgOpen(false)}
        aria-labelledby='info-dialog-title'
        aria-describedby='info-dialog-description'
      >
        <DialogTitle id='info-dialog-title'>
          More Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='info-dialog-description'>
            <div dangerouslySetInnerHTML={{ __html: info }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ev => setDlgOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default InfoButton

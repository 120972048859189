import { createSlice } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'

import {
  getAccessToken,
  removeAccessToken
} from '../../../utils/localstorageAuth'

const history = createBrowserHistory()

const initialState = {
  isSignedIn: !!getAccessToken(),
  isFetching: false,
  userData: null,
  isError: false,
  errorMessage: ''
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.isSignedIn = true
      state.userData = action.payload
    },
    signOut: (state) => {
      removeAccessToken()
      state.isSignedIn = false
      history.push('/')
    },
    setUser: (state, action) => {
      state.profile = action.payload
    }
  }
})

const { actions, reducer } = authSlice
export const { signOut, userData } = actions

export default reducer

import React from 'react'

// import Pagination from 'components/common/Pagination'

function sortTable (n) {
  let table; let rows; let switching; let i; let x; let y; let shouldSwitch; let dir; let switchcount = 0
  table = document.getElementById('projects_table')
  switching = true
  dir = 'asc'
  while (switching) {
    switching = false
    rows = table.rows
    for (i = 1; i < (rows.length - 1); i++) {
      shouldSwitch = false
      x = rows[i].getElementsByTagName('TD')[n]
      y = rows[i + 1].getElementsByTagName('TD')[n]
      if (dir === 'asc') {
        if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
          shouldSwitch = true
          break
        }
      } else if (dir === 'desc') {
        if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
          shouldSwitch = true
          break
        }
      }
    }
    if (shouldSwitch) {
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i])
      switching = true
      switchcount++
    } else {
      if (switchcount === 0 && dir === 'asc') {
        dir = 'desc'
        switching = true
      }
    }
  }
}

function Item ({ item }) {
  return (
    <>
      <tr key={item.id}>
        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6'>
          {item?.name}
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{item?.brand}</td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{item?.year}</td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{item?.live.status}</td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{item?.live.assetsVersion}</td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{item?.preview.assetsVersion}</td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{item?.created}</td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{item?.lastUpdated}</td>
        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
          <a href={`/admin/edit/${item.domain}`} className='text-indigo-600 hover:text-indigo-100'>
            Edit<span className='sr-only'>, {item.name}</span>
          </a>
        </td>
      </tr>
    </>
  )
}

export default function Table ({ data, total, page, limit }) {
  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='sm:flex sm:items-center'>
        <div className='sm:flex-auto'>
          <h1 className='text-xl font-semibold text-gray-100'>CMS Admin</h1>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-16 sm:flex-none'>
          <a href='/admin/new'>
            <button
              type='button'
              className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'
            >
              New Project
            </button>
          </a>
        </div>
      </div>
      <div className='mt-8 flex flex-col'>
        <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table id='projects_table' className='min-w-full bg-black divide-y divide-gray-300'>
                <thead className=''>
                  <tr>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6 cursor-pointer' onClick={() => { sortTable(0) }}>
                      Name
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100 cursor-pointer' onClick={() => { sortTable(1) }}>
                      Brand
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100 cursor-pointer' onClick={() => { sortTable(2) }}>
                      Year
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100 cursor-pointer' onClick={() => { sortTable(3) }}>
                      Live Status
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100 cursor-pointer' onClick={() => { sortTable(4) }}>
                      Live Ver
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100 cursor-pointer' onClick={() => { sortTable(5) }}>
                      Preview Ver
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100 cursor-pointer' onClick={() => { sortTable(6) }}>
                      Created
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100 cursor-pointer' onClick={() => { sortTable(7) }}>
                      Updated
                    </th>
                    <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                  {data && data.map((item) => (
                    <Item key={item.id} item={item} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <Pagination total={total} page={page} limit={limit} /> */}
    </div>
  )
}

export const multiplyScalar = 0.2 // old ver is 0.5

// you need check public folder
export const sprites = {
  arrow: require('../assets/shapes/arrows-with-transparent-red.png'),
  arrowYellow: require('../assets/shapes/arrows-with-transparent-yellow.png'),
  circe: require('../assets/shapes/circe_normal_red.png'),
  circeYellow: require('../assets/shapes/circe_normal_yellow.png'),
  square: require('../assets/shapes/square_red.png'),
  squareYellow: require('../assets/shapes/square_yellow.png'),
  star: '/png/sprites/star.png',
  arrow_2: require('../assets/shapes/arrows-with-transparent-red.png'),
  circe_2: require('../assets/shapes/circe_normal_red.png'),
  square_2: require('../assets/shapes/square_red.png'),
  arrow_3: require('../assets/shapes/arrows-with-transparent-red.png'),
  circe_3: require('../assets/shapes/circe_normal_red.png'),
  square_3: require('../assets/shapes/square_red.png')
}

export const DEFAULT = 'DEFAULT'
// ui menu
export const menu = {
  CONTENT_MENUS: 'CONTENT_MENUS',
  GALLERY_MENUS: 'GALLERY_MENUS',
  DASHBOARD_MENUS: 'DASHBOARD_MENUS',
  // item
  ADD_MENU: 'ADD_MENU',
  SHOW_MENU: 'SHOW_MENU',
  HIDE_MENU: 'HIDE_MENU',
  ADD_SPRITE_NANO_MENU: 'ADD_SPRITE_NANO_MENU',
  VIEW_NANO_MENU: 'VIEW_NANO_MENU',
  // status
  MENU_DISABLE: 'MENU_DISABLE',
  MENU_ENABLE: 'MENU_ENABLE'
}

// ui slice
export const slice = {
  type: {
    STATIC_IMG: 'STATIC_IMG'
  },
  canvas: {
    draw: {
      ARROW_DRAW: 'ARROW_DRAW',
      CIRCE_DRAW: 'CIRCE_DRAW',
      SQUARE_DRAW: 'SQUARE_DRAW',
      // status
      SHOW_DRAW: 'SHOW_DRAW'
    }
  },
  pano: {
    addOrEdit: {
      ADD_PANO: 'ADD_PANO',
      EDIT_PANO: 'EDIT_PANO'
    },
    spriteSelected: {
      CIRCE_DRAW_PANO: 'CIRCE_DRAW_PANO',
      ARROW_DRAW_PANO: 'ARROW_DRAW_PANO',
      SQUARE_DRAW_PANO: 'SQUARE_DRAW_PANO',
      EDIT_DRAW_PANO: 'EDIT_DRAW_PANO', // not thing, like DEFAULT
      STAR_DRAW_PANO: 'STAR_DRAW_PANO',
      MY_DRAW_PANO: 'MY_DRAW_PANO'
    }
  }
}

// ui configre
export const configre = {
  header: {
    status: {
      ADD_CONFIGRE: 'ADD_CONFIGRE'
    },
    active: {
      ARROW_CONFIGRE: 'ARROW_CONFIGRE',
      CIRCE_CONFIGRE: 'CIRCE_CONFIGRE',
      SQUARE_CONFIGRE: 'SQUARE_CONFIGRE',
      ARROW_CONFIGRE_PANO: 'ARROW_CONFIGRE_PANO',
      CIRCE_CONFIGRE_PANO: 'CIRCE_CONFIGRE_PANO',
      SQUARE_CONFIGRE_PANO: 'SQUARE_CONFIGRE_PANO',
      STAR_CONFIGRE_PANO: 'STAR_CONFIGRE_PANO',
      MY_DRAW_CONFIGRE_PANO: 'MY_DRAW_CONFIGRE_PANO',
      EDIT_CONFIGRE_PANO: 'EDIT_CONFIGRE_PANO'
    }
  },
  content: {
    ADD_MARKETS: 'ADD_MARKETS',
    ADD_SELECTED_MARKET: 'ADD_SELECTED_MARKET',
    SHOW_CMT: 'SHOW_CMT'
  }
}

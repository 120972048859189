import React, { useState } from 'react'
import { withRouter, useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import propTypes from 'prop-types'

import './Review.scss'

import { useLightTheme } from 'hooks/useLightTheme'

import Configure from 'components/Configure/Configure'
import {
  HomeDarkIcon,
  HomeLightIcon
} from 'components/icons/icons'
import AddCommentTool from 'components/AddCommentTool/AddCommentTool'
import CameraRender from 'components/CameraRender/CameraRender'
import BrandIcon from 'components/BrandIcon/BrandIcon'
import CommentStatusTool from 'components/CommentStatusTool/CommentStatusTool'
import DownloadButton from 'components/DownloadButton/DownloadButton'
import InfoButton from 'components/InfoButton'
import Loading from 'components/common/Loading'

import {
  SUBARU
} from 'constants/constants'

const Review = () => {
  const history = useHistory()

  const { isVanityURL, selectedProject: { version, brand, annotationEnabled } } = useSelector(
    (state) => state.projects
  )

  const [isLightTheme] = useLightTheme(brand)

  const [activeTool, setActiveTool] = useState(null)
  const [filterComments, setFilterComments] = useState([])

  return (
    <>
      {brand
        ? <div className={clsx('review', isLightTheme && 'light-theme')}>
          {
            !isVanityURL &&
              <div className='review_home-icon' onClick={ev => history.push('/projects')}>
                {isLightTheme ? <HomeDarkIcon /> : <HomeLightIcon />}
              </div>
          }
          <div>
            <BrandIcon brand={brand} />
            <div className='review-layout'>
              <div className='config-tools'>
                <Configure />
              </div>
              <div className='car-images'>
                {brand && (
                  <>
                    <div
                      className={clsx(
                        'viewport_bar',
                        brand === SUBARU ? 'viewport_bar-subaru' : isLightTheme ? 'viewport_bar-lightTheme' : ''
                      )}
                    >
                      <>
                        <div className='annotation-bar'>
                          <h4 className='version-number'>Version: {version}</h4>
                          <div className='annotation-tools'>
                            {annotationEnabled && (
                              <>
                                <AddCommentTool
                                  activeTool={activeTool}
                                  setActiveTool={setActiveTool}
                                />
                                <CommentStatusTool setFilterComments={setFilterComments} />
                              </>
                            )}
                          </div>
                        </div>
                        <div className='viewport_bar_controls'>
                          <InfoButton />
                          <DownloadButton />
                        </div>
                      </>
                    </div>

                    <div className='viewport'>
                      <CameraRender
                        brand={brand}
                        filterComments={filterComments}
                        activeTool={activeTool}
                        setActiveTool={setActiveTool}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          </div>
        : <Loading />}
    </>
  )
}

Review.propTypes = {
  location: propTypes.object
}

export default withRouter(Review)

import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import './styles.scss'

const Loading = () => (
  <div className='spinner'>
    <CircularProgress />
  </div>
)

export default Loading

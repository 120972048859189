import React, { useEffect, useState } from 'react'

// local
import PageHeader from 'components/common/PageHeader/PageHeader'
import Table from 'components/Admin/Table'
import { listProjectsAPI } from 'redux/reducers/projects/projectAPI'
import './Admin.css'

const Admin = () => {
  const [data, setData] = useState({ data: [], total: 0, page: 1, limit: 100 })

  // projects
  useEffect(() => {
    listProjectsAPI({ page: 1, limit: 100 }).then((data) => {
      setData({ data: data.project, total: data.total, page: data.page, limit: data.limit })
    })
  }, [])

  return (
    <>
      <PageHeader />
      <div className='admin'>
        <div className='p-6 max-w-7xl mx-auto'>
          <div className='w-full'>
            <Table data={data.data} total={data.total} page={data.page} limit={data.limit} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Admin

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { LIVE_VIEW } from 'constants/configurations'

import { listProjectsAPI, getProjectByURLAPI } from './projectAPI'

const PAGE_DEFAULT = 1
const LIMIT_DEFAULT = 10

const initialState = {
  selectedProject: {
    id: '',
    brand: '',
    name: '',
    version: '',
    year: '',
    flat: true,
    annotationEnabled: false,
    configurations: {
      extColors: [],
      intColors: [],
      cameras: []
    },
    moreInfo: ''
  },
  total: 0,
  allProjects: [],
  collection: [],
  isFetching: false,
  isVanityURL: true
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    fetchProjects: (state, { payload }) => {

    },
    setSelectedProject: (state, { payload }) => {
      state.selectedProject = payload
      state.isFetching = false
    },
    clearSelectedProject: (state, { payload }) => {
      state.selectedProject = initialState.selectedProject
    },
    setProjects: (state, { payload }) => {
      state.allProjects = payload
      state.isFetching = false
    },
    setCollection: (state, { payload }) => {
      state.collection = payload
    },
    setTotal: (state, { payload }) => {
      state.total = payload
    },
    startFetching: (state) => {
      state.isFetching = true
    },
    finishFetching: (state) => {
      state.isFetching = false
    },
    updateCommentProjectPano: (state, { payload }) => {
      state.selectedProject.pano.comments = payload
    },
    setIsVanityURL: (state, { payload }) => {
      state.isVanityURL = payload
    }
  }
})

const { actions, reducer } = projectsSlice

export const initializeProjects = createAsyncThunk(
  'projects/initializeProjects',
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(actions.startFetching())
      const data = await getProjectByURLAPI()
      if (data != null && data.length > 0) {
        const project = data[0].data
        if (project) {
          thunkAPI.dispatch(setSelectedProject({
            ...project,
            version: project[LIVE_VIEW].assetsVersion,
            info: project.moreInfo?.[LIVE_VIEW]
          }))
          return '/review'
        }
      }
    } catch (error) {
      console.log(error)
    }

    thunkAPI.dispatch(actions.setIsVanityURL(false))
    try {
      const data = await listProjectsAPI({ page: PAGE_DEFAULT, limit: LIMIT_DEFAULT })
      thunkAPI.dispatch(actions.setProjects(data.project))
      thunkAPI.dispatch(actions.setTotal(data.total))
    } catch (error) {
      console.log(error)
    }
    return '/projects'
  }
)

export const {
  setProjects,
  setCollection,
  setSelectedProject,
  clearSelectedProject,
  updateCommentProjectPano,
  setIsVanityURL
} = actions

export default reducer

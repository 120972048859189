import React from 'react'

// local
import PageHeader from 'components/common/PageHeader/PageHeader'
import Form from 'components/Admin/Form'
import './Admin.css'

const AdminNew = () => {
  return (
    <>
      <PageHeader />
      <div className='admin'>
        <div className='p-6 max-w-7xl mx-auto'>
          <div className='w-full'>
            <Form />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminNew

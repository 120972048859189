import React, { useEffect, useMemo, useState, useRef } from 'react'
import useWindowSize from 'hooks/useWindowSize'
import 'components/ThreeDView/ThreeDView.scss'
import Slider from 'components/Slider/Slider'
import { slicer } from 'components/CameraRender/data'
import Comments from 'components/Comments/Comments'
import ItemsList from 'components/common/List/List'
import useDebounce from 'hooks/useDebounce'
import { CircularProgress } from '@material-ui/core'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setFrameNum } from 'redux/reducers/ui/uiSlice'
import useAspectRatio from '../../hooks/useAspectRatio'

const ThreeDView = ({
  images,
  activeTool,
  setActiveTool,
  filterComments,
  showComments
}) => {
  const {
    configre: {
      cameraControls: { frameNum: frame }
    }
  } = useSelector((state) => state.ui)
  const dispatch = useDispatch()
  const [isDragging, setDragging] = useState(false)
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 })
  const debouncedValue = useDebounce(frame, 500)
  const [forceCanvas, setForceCanvas] = useState(false) // If comments are shown, force canvas for render comment shapes
  const [width] = useWindowSize()
  const containerRef = useRef(null)
  useAspectRatio(containerRef, images[0], '3dView')
  const [loadedImageCount, setLoadedImageCount] = useState(0)
  const [imageArray, setImageArray] = useState([])
  const handleMouseDown = (ev) => {
    if (activeTool) {
      setDragging(false)
      return
    }
    setForceCanvas(false)
    setStartPoint({
      x: ev.clientX,
      y: ev.clientY,
      frame
    })
    setDragging(true)
  }

  const handleMouseMove = (ev) => {
    if (isDragging) {
      const fullRad = width / 2
      const diff = ev.clientX - startPoint.x
      const index =
        (startPoint.frame +
          (Math.ceil((images.length / fullRad) * diff) % images.length) +
          (diff > 0 ? 0 : images.length)) %
        images.length
      dispatch(setFrameNum(index))
    }
  }

  useMemo(() => {
    if (showComments) {
      setForceCanvas(true)
    } else {
      setForceCanvas(false)
      setLoadedImageCount(0)
    }
  }, [showComments])

  useEffect(() => {
    if (showComments) {
      setForceCanvas(true)
    }
  }, [debouncedValue])

  useMemo(() => {
    const oldArray = imageArray.join('')
    const newArray = images.join('')
    if (oldArray !== newArray) {
      setImageArray(images)
      setLoadedImageCount(0)
    }
  }, [images])

  return (
    <div className='single-car' ref={containerRef}>
      <div
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={() => setDragging(false)}
        onMouseLeave={() => setDragging(false)}
        className='main'
        id='3dView'
        style={
          !activeTool && !forceCanvas
            ? { display: 'flex', alignItems: 'center' }
            : {}
        }
      >
        {loadedImageCount !== imageArray.length && !forceCanvas && (
          <div className='loading-container'>
            <CircularProgress />
          </div>
        )}
        {!activeTool && !forceCanvas ? (
          imageArray.map((img, i) => (
            <img
              key={i}
              style={{
                zIndex:
                  loadedImageCount === imageArray.length && !forceCanvas
                    ? 0
                    : -1,
                height: '100%',
                width: '100%'
              }}
              className={`${
                i === frame ? 'single-image show' : 'single-image  hide'
              }`}
              src={img}
              onLoad={() => setLoadedImageCount(loadedImageCount + 1)}
              // width={width}
              // height={width / 1.779}
            />
          ))
        ) : (
          <Slider
            imageSource={images[frame]}
            slicer={slicer}
            setActiveTool={setActiveTool}
            frameNum={frame}
          />
        )}
      </div>
      {showComments && (
        <div className='show-comment'>
          <Comments
            title='COMMENTS'
            details={<ItemsList list={filterComments} />}
          />
        </div>
      )}
    </div>
  )
}

ThreeDView.propTypes = {
  images: propTypes.array,
  activeTool: propTypes.string,
  setActiveTool: propTypes.func,
  filterComments: propTypes.array,
  showComments: propTypes.bool
}

export default ThreeDView

export const slicer = [
  {
    cameraName: 'Camera Name 1',
    img: '/png/hynday/1.png',
    description:
      '1 Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
    comments: [
      {
        id: 'cmt01',
        canvas: {
          type: 'ARROW',
          width: 637,
          height: 358,
          fromX: 79,
          fromY: 57,
          toX: 247,
          toY: 135
        },
        commentDetail: {
          user: 'Mihajlov',
          createdDate: '1/8/2020',
          content: 'This looks like trash , this is cmt 1'
        }
      },
      {
        id: 'cmt02',
        canvas: {
          type: 'ARROW',
          width: 637,
          height: 358,
          fromX: 607,
          fromY: 21,
          toX: 495,
          toY: 83
        },
        commentDetail: {
          user: 'Mihajlov',
          createdDate: '2/8/2020',
          content: 'This looks like trash , this is cmt 2'
        }
      },
      {
        id: 'cmt03',
        canvas: {
          type: 'CIRCE',
          width: 724,
          height: 407,
          fromX: 393,
          fromY: 212,
          toX: 512,
          toY: 365
        },
        commentDetail: {
          user: 'Mihajlov',
          createdDate: '3/8/2020',
          content: 'This looks like trash , this is cmt 3'
        }
      },
      {
        id: 'cmt04',
        canvas: {
          type: 'CIRCE',
          width: 724,
          height: 407,
          fromX: 139,
          fromY: 172,
          toX: 195,
          toY: 210
        },
        commentDetail: {
          user: 'Mihajlov',
          createdDate: '4/8/2020',
          content: 'This looks like trash , , this is cmt 4'
        }
      },
      {
        id: 'cmt05',
        canvas: {
          type: 'SQUARE',
          width: 674,
          height: 379,
          fromX: 267,
          fromY: 124,
          toX: 385,
          toY: 172
        },
        commentDetail: {
          user: 'Mihajlov',
          createdDate: '5/8/2020',
          content: 'This looks like trash , , this is cmt 5'
        }
      }
    ],
    config: [
      {
        title: 'Config Name 1',
        description:
          '1Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/1.png'
      },
      {
        title: 'Config Name 2',
        description:
          '2Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/2.png'
      },
      {
        title: 'Config Name 3',
        description:
          '3Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/3.png'
      },
      {
        title: 'Config Name 4',
        description:
          '4Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/4.png'
      },
      {
        title: 'Config Name 5',
        description:
          '5Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/5.png'
      },
      {
        title: 'Config Name 6',
        description:
          '6Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/6.png'
      }
    ]
  },
  {
    cameraName: 'Camera Name 2',
    img: '/png/hynday/2.png',
    description:
      '2 Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
    comments: [
      {
        id: 'cmt06',
        canvas: {
          type: 'ARROW',
          width: 637,
          height: 358,
          fromX: 79,
          fromY: 57,
          toX: 247,
          toY: 135
        },
        commentDetail: {
          user: 'Mihajlov',
          createdDate: '6/8/2020',
          content: 'This looks like trash , this is cmt 6'
        }
      }
    ],
    config: [
      {
        title: 'Config Name 1',
        description:
          '1Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/1.png'
      },
      {
        title: 'Config Name 2',
        description:
          '2Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/2.png'
      },
      {
        title: 'Config Name 3',
        description:
          '3Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/3.png'
      },
      {
        title: 'Config Name 4',
        description:
          '4Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/4.png'
      },
      {
        title: 'Config Name 5',
        description:
          '5Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/5.png'
      },
      {
        title: 'Config Name 6',
        description:
          '6Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/6.png'
      }
    ]
  },
  {
    cameraName: 'Camera Name 3',
    img: '/png/hynday/3.png',
    comments: [
      {
        id: 'cmt07',
        canvas: {
          type: 'CIRCE',
          width: 724,
          height: 407,
          fromX: 393,
          fromY: 212,
          toX: 512,
          toY: 365
        },
        commentDetail: {
          user: 'Mihajlov',
          createdDate: '7/8/2020',
          content: 'This looks like trash , , this is cmt 7'
        }
      }
    ],
    config: [
      {
        title: 'Config Name 1',
        description:
          '1Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/1.png'
      },
      {
        title: 'Config Name 2',
        description:
          '2Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/2.png'
      },
      {
        title: 'Config Name 3',
        description:
          '3Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/3.png'
      },
      {
        title: 'Config Name 4',
        description:
          '4Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/4.png'
      },
      {
        title: 'Config Name 5',
        description:
          '5Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/5.png'
      },
      {
        title: 'Config Name 6',
        description:
          '6Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/6.png'
      }
    ],
    description:
      '3 Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.'
  },
  {
    cameraName: 'Camera Name 4',
    img: '/png/hynday/4.png',
    comments: [
      {
        id: 'cmt08',
        canvas: {
          type: 'CIRCE',
          width: 724,
          height: 407,
          fromX: 139,
          fromY: 172,
          toX: 195,
          toY: 210
        },
        commentDetail: {
          user: 'Mihajlov',
          createdDate: '8/8/2020',
          content: 'This looks like trash , this is cmt 8'
        }
      }
    ],
    config: [
      {
        title: 'Config Name 1',
        description:
          '1Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/1.png'
      },
      {
        title: 'Config Name 2',
        description:
          '2Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/2.png'
      },
      {
        title: 'Config Name 3',
        description:
          '3Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/3.png'
      },
      {
        title: 'Config Name 4',
        description:
          '4Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/4.png'
      },
      {
        title: 'Config Name 5',
        description:
          '5Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/5.png'
      },
      {
        title: 'Config Name 6',
        description:
          '6Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/6.png'
      }
    ],
    description:
      '4 Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.'
  },
  {
    cameraName: 'Camera Name 5',
    img: '/png/hynday/5.png',
    comments: [
      {
        id: 'cmt09',
        canvas: {
          type: 'SQUARE',
          width: 674,
          height: 379,
          fromX: 267,
          fromY: 124,
          toX: 385,
          toY: 172
        },
        commentDetail: {
          user: 'Mihajlov',
          createdDate: '9/8/2020',
          content: 'This looks like trash , this is cmt 9'
        }
      }
    ],
    config: [
      {
        title: 'Config Name 1',
        description:
          '1Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/1.png'
      },
      {
        title: 'Config Name 2',
        description:
          '2Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/2.png'
      },
      {
        title: 'Config Name 3',
        description:
          '3Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/3.png'
      },
      {
        title: 'Config Name 4',
        description:
          '4Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/4.png'
      },
      {
        title: 'Config Name 5',
        description:
          '5Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/5.png'
      },
      {
        title: 'Config Name 6',
        description:
          '6Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/6.png'
      }
    ],
    description:
      '5 Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.'
  },
  {
    cameraName: 'Camera Name 6',
    img: '/png/hynday/6.png',
    comments: [
      {
        id: 'cmt10',
        canvas: {
          type: 'ARROW',
          width: 637,
          height: 358,
          fromX: 79,
          fromY: 57,
          toX: 247,
          toY: 135
        },
        commentDetail: {
          user: 'Mihajlov',
          createdDate: '10/8/2020',
          content: 'This looks like trash , this is cmt 10'
        }
      },
      {
        id: 'cmt11',
        canvas: {
          type: 'CIRCE',
          width: 724,
          height: 407,
          fromX: 139,
          fromY: 172,
          toX: 195,
          toY: 210
        },
        commentDetail: {
          user: 'Mihajlov',
          createdDate: '11/8/2020',
          content: 'This looks like trash, this is cmt 11'
        }
      }
    ],
    config: [
      {
        title: 'Config Name 1',
        description:
          '1Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/1.png'
      },
      {
        title: 'Config Name 2',
        description:
          '2Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/2.png'
      },
      {
        title: 'Config Name 3',
        description:
          '3Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/3.png'
      },
      {
        title: 'Config Name 4',
        description:
          '4Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/4.png'
      },
      {
        title: 'Config Name 5',
        description:
          '5Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/5.png'
      },
      {
        title: 'Config Name 6',
        description:
          '6Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.',
        img: '/png/hynday/6.png'
      }
    ],
    description:
      '6 Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов.'
  }
]

import setupAxios from '../../../setupAxios'
import { SIGNIN_URL } from '../../../constants/constants'
import axios from 'axios'

const signup = ({ email, firstName, lastName, password1, password2 }) => {
  const formData = new FormData()
  formData.append('email', email)
  formData.append('first_name', firstName)
  formData.append('last_name', lastName)
  formData.append('password1', password1)
  formData.append('password2', password2)
  return setupAxios.post(SIGNIN_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const signin = ({ email, password }) => {
  return setupAxios.post('/signin/', { email, password })
}

const authSignIn = async ({ email, password }) => {
  return axios
    .post(`${process.env.REACT_APP_AUTH_API}/v1/auth/ajax/login`, {
      email,
      password,
      client_id: process.env.REACT_APP_CLIENT_ID
    })
    .then((response) => {
      return {
        status: 200,
        data: {
          ...response.data
        }
      }
    })
    .catch(function (error) {
      console.log('error', error.message)
      return { data: error.message }
    })
}

const authAPI = {
  signup,
  signin,
  authSignIn
}

export default authAPI

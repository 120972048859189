import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import 'components/common/CheckBox/CheckBox.scss'
import propTypes from 'prop-types'

export default function MyCheckBox ({ checkBoxList, checkedValues }) {
  const [state, setState] = React.useState({})

  const handleChange = (event) => {
    const updateValues = {
      ...state,
      [event.target.name]: event.target.checked
    }
    setState(
      Object.keys(updateValues).reduce(
        (prev, cur) => ({
          ...prev,
          [cur]: false,
          [event.target.name]: event.target.checked
        }),
        {}
      )
    )

    const filterOnlyTrue = Object.keys(updateValues).filter(
      (key) => updateValues[key] === true && key
    )

    checkedValues(filterOnlyTrue)
  }

  return (
    <FormGroup row className='checkbox-wrapper'>
      {checkBoxList &&
        Array.isArray(checkBoxList) &&
        checkBoxList.length > 0 &&
        checkBoxList.map(({ label, value }, i) => {
          return (
            <FormControlLabel
              key={i}
              className='label'
              control={
                <Checkbox
                  checked={!!state[value]}
                  className='label'
                  onChange={handleChange}
                  name={value}
                  color='primary'
                />
              }
              label={label}
            />
          )
        })}
    </FormGroup>
  )
}

MyCheckBox.propTypes = {
  checkBoxList: propTypes.array,
  checkedValues: propTypes.func
}

import React, { useState } from 'react'
import clsx from 'clsx'
import 'components/common/Select/Select.scss'
import { MenuItem, FormControl, Select } from '@material-ui/core'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import propTypes from 'prop-types'

const MySelect = (props) => {
  const { label, value, name, data, key, handleChange, className, lightTheme } =
    props
  const [isOpen, setIsOpen] = useState(false)
  return (
    <FormControl
      className={clsx(
        className && className,
        lightTheme && 'light'
      )}
    >
      <label className='selectLabel'>{label}</label>
      <div className={clsx('selectWrapper', label && 'selectWrapper-label')}>
        <Select
          label='Trims'
          value={value}
          name={name}
          onChange={handleChange}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          disableUnderline={!!className}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          {data.map((item, i) => (
            <MenuItem key={i} value={item.value}>
              {item.title || item[key]}
            </MenuItem>
          ))}
        </Select>
        <div className='iconDropDown'>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
    </FormControl>
  )
}

MySelect.propTypes = {
  label: propTypes.string,
  value: propTypes.string,
  name: propTypes.string,
  data: propTypes.array,
  key: propTypes.string,
  handleChange: propTypes.func,
  className: propTypes.string,
  lightTheme: propTypes.bool
}

export default MySelect

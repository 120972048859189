import React, { useState, useEffect, useRef } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import StopIcon from '@material-ui/icons/Stop'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Icon from 'components/common/Icon/Icon'
import 'components/AddCommentTool/AddCommentTool.scss'
import { ARROW, CIRCLE, SQUARE } from 'constants/constants'
import { AddCommentIcon, HideCommentIcon } from 'components/icons/icons'
import propTypes from 'prop-types'
import {
  clickMenuPano,
  selectedMarketPano,
  setIsCommentModePano,
  setSpriteSelectedPano
} from 'redux/reducers/ui/uiSlice'
import { clickMenu, closeAdd, selectedMarket } from 'redux/reducers/canvas/canvasSlice'
import { useDispatch } from 'react-redux'
import ModalDisplay from '../common/Modal/Modal'
import CallMadeOutlinedIcon from '@material-ui/icons/CallMadeOutlined'
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined'
import StopOutlinedIcon from '@material-ui/icons/StopOutlined'
import Button from '../common/Button/Button'
import { slice } from 'constants/ui'

const AddCommentTool = ({ setActiveTool, activeTool }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const anchorRef = useRef(null)
  const {
    pano: {
      spriteSelected: { ARROW_DRAW_PANO, CIRCE_DRAW_PANO, SQUARE_DRAW_PANO }
    }
  } = slice

  const onToolClick = (toolType) => {
    if (toolType) {
      setActiveTool(toolType)
      dispatch(clickMenuPano('ADD_MENU'))
      dispatch(
        setSpriteSelectedPano(
          toolType === 'circle' ? CIRCE_DRAW_PANO : toolType === 'square' ? SQUARE_DRAW_PANO : toolType === 'arrow' ? ARROW_DRAW_PANO : null
        )
      )
      dispatch(setIsCommentModePano(toolType))
      setOpenModal(true)
    } else {
      dispatch(clickMenuPano('HIDE_MENU'))
      dispatch(setSpriteSelectedPano('DEFAULT'))
      dispatch(closeAdd())
      setActiveTool(null)
      setOpenModal(false)
    }
  }

  const onConfirmTool = () => {
    dispatch(clickMenu('ADD_MENU'))
    if (activeTool === 'arrow') {
      dispatch(selectedMarket('ARROW_CONFIGRE'))
      dispatch(selectedMarketPano('ARROW_CONFIGRE'))
    }
    if (activeTool === 'square') {
      dispatch(selectedMarket('SQUARE_CONFIGRE'))
      dispatch(selectedMarketPano('SQUARE_CONFIGRE'))
    }
    if (activeTool === 'circle') {
      dispatch(selectedMarket('CIRCE_CONFIGRE'))
      dispatch(selectedMarketPano('CIRCE_CONFIGRE'))
    }
    dispatch(setIsCommentModePano(activeTool))
    setOpenModal(false)
  }

  const handleToggle = () => {
    if (activeTool) {
      setOpen(false)
      onToolClick(null)
      return
    }
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  function handleListKeyDown (event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const menuItemClicked = (event, value) => {
    onToolClick(value)
    handleClose(event)
  }
  return (
    <div className='add-comment'>
      <div
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        {activeTool
          ? (
            <Icon item={<HideCommentIcon />} />
            )
          : (
            <Icon item={<AddCommentIcon />} />
            )}
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  className='add-comment_tools'
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={(e) => menuItemClicked(e, ARROW)}>
                    <ArrowUpwardIcon className='arrow-tool' />
                  </MenuItem>
                  <MenuItem onClick={(e) => menuItemClicked(e, CIRCLE)}>
                    <FiberManualRecordIcon />
                  </MenuItem>
                  <MenuItem onClick={(e) => menuItemClicked(e, SQUARE)}>
                    <StopIcon />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <ModalDisplay
        modalBody={
          <div className='review_body-text'>
            <div className='review_body-text_text'>
              click the point where the marker should start and then the point
              where the marker should end
            </div>
            <div className='selected-icon'>
              {activeTool === ARROW && (
                <CallMadeOutlinedIcon style={{ fontSize: 60 }} />
              )}
              {activeTool === CIRCLE && (
                <RadioButtonUncheckedOutlinedIcon style={{ fontSize: 60 }} />
              )}
              {activeTool === SQUARE && (
                <StopOutlinedIcon style={{ fontSize: '4rem' }} />
              )}
            </div>

            <div className='next-wrapper'>
              <Button className='next-button' onClick={onConfirmTool}>
                Next
              </Button>
            </div>
          </div>
        }
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </div>
  )
}

AddCommentTool.propTypes = {
  activeTool: propTypes.string,
  setActiveTool: propTypes.func.isRequired
}

export default AddCommentTool

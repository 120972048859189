import React, { useEffect, useState } from 'react'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import authAPI from 'redux/reducers/auth/authAPI'
import { Alert } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { createBrowserHistory } from 'history'
import { LogoIcon } from 'components/icons/icons'
import LoginFooter from 'components/LoginFooter/LoginFooter'
import { validateEmail } from 'utils/validateEmail'
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken
} from 'utils/localstorageAuth'
import 'pages/Login/Login.scss'

const Login = () => {
  const dispatch = useDispatch()
  const [form, setForm] = useState({
    email: '',
    password: ''
  })
  const history = createBrowserHistory()
  const [error, setError] = useState()
  const initialState = {
    isSignedIn: !!getAccessToken(),
    isFetching: false,
    // isSuccess: false,
    userData: [],
    isError: false,
    errorMessage: ''
  }
  const handleSubmitLogin = () => {
    const { email, password } = form
    const checkEmail = validateEmail(email)
    if (!checkEmail) {
      setError('Please input correct email address')
    }
    if (!password) {
      setError('Please enter correct credentails')
    }
    checkEmail && password && dispatch(signin(form))
  }

  const signin = createAsyncThunk('auth/signin', async (params, thunkAPI) => {
    const { email, password } = params

    return authAPI
      .authSignIn({ email, password })
      .then(({ data, status }) => {
        if (status === 200 && data.status !== false) {
          if (data.data.tok) {
            window.localStorage.setItem('userData', JSON.stringify(data.data))
            setAccessToken(data.data.tok)
            thunkAPI.dispatch(
              authSlice.actions.signIn({
                data
              })
            )
            return data
          }
        } else {
          setError('AuthSignIn failed. Invalid Email or Password')
          return thunkAPI.rejectWithValue(data)
        }
      })
      .catch((error) => {
        thunkAPI.rejectWithValue(error.response.data)
        throw new Error(error.response.data)
      })
  })

  useEffect(() => {}, [form])

  const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      signIn: (state, action) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.isSignedIn = true
        state.userData = action.payload
        // history.push('/projects')
      },
      signOut: (state) => {
        removeAccessToken()
        // state = { ...state, isSignedIn: false };
        state.isSignedIn = false
        history.push('/')
      },
      setUser: (state, action) => {
        state.profile = action.payload
      }
    }
  })

  const setFormHendler = (e) => {
    setForm((prevForm) => ({
      ...prevForm,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <>
      <div className='login'>
        <div className='logo'>
          <LogoIcon width='75px' />
        </div>
        <div className='login_form'>
          <div className='alert_style'>
            {error && <Alert padding='10px'>{error}</Alert>}
          </div>
          <div className='login_input'>
            <input
              type='text'
              name='email'
              placeholder='email'
              onChange={setFormHendler}
              value={form.email}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmitLogin()
                }
              }}
            />
            <input
              type='password'
              name='password'
              placeholder='password'
              onChange={setFormHendler}
              value={form.password}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmitLogin()
                }
              }}
            />
          </div>
          <div className='login_form_reset'>
            <div className='reset-button'>
              <p className='reset-password-text'>Reset password</p>
            </div>
            <div className='login-button' onClick={handleSubmitLogin}>
              <p className='reset-password-text'>LOGIN</p>
            </div>
          </div>
        </div>
      </div>
      <LoginFooter />
    </>
  )
}

export default Login

import React from 'react'

import AppRouter from './router/Router'
import CssBaseline from '@material-ui/core/CssBaseline'
import GlobalStyles from './styles/GlobalStyles'
import './styles/scss/index.scss'
import NotificationBar from './components/common/NotificationBar/NotificationBar'

function App () {
  return (
    <div className='App'>
      <CssBaseline />
      <GlobalStyles />
      <AppRouter />
      <NotificationBar />
    </div>
  )
}

export default App

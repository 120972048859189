import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { customCanvasMatchCurrentScreen } from 'redux/reducers/canvas/canvasSlice'
import { convertToDataCanvasSelect } from 'utils/panoViewFunctions'
import {
  setCameraControl,
  highlightAnnotation
} from 'redux/reducers/ui/uiSlice'
import propTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: '0px'
  },
  inline: {
    display: 'inline'
  },
  hover: {
    '&:hover': {
      backgroundColor: '#033a7a33',
      transition: '0.1s ease',
      scale: '1.05'
    }
  }
}))

export default function ItemsList ({ list }) {
  const dispatch = useDispatch()
  const {
    configre: {
      cameraControls
      // selectedTrimValue,
      // selectedExterior,
      // selectedInterior,
      // showComments
    },
    saveComments
  } = useSelector((state) => state.ui)
  const selectedProject = useSelector((state) => state.projects.selectedProject)

  const selectedCameraControl =
    cameraControls &&
    Array.isArray(cameraControls.data) &&
    cameraControls.data.find(({ value }) => value === cameraControls.value)

  const classes = useStyles()
  const handleClick = (id) => {
    const { id: projectID } = selectedProject || {
      version: '',
      id: ''
    }
    // find comment on selected project
    const filterProjectComment = saveComments.filter(
      (projectId) => projectId.projectId === projectID
    )

    const selectedComment = filterProjectComment.filter(
      (fileterItem) => fileterItem.id === id
    )

    /*
    // find Color Comment
    const filterColorComments = filterProjectComment.filter(
      (filterItem) =>
        filterItem.exteriorColorId === selectedExterior.value &&
        filterItem.cameraControlId === selectedCameraControl.value &&
        filterItem.commentType === 'color_comment'
    )

    // find Camera Comment
    const filterCameraComment = filterProjectComment.filter(
      (filterItem) =>
        filterItem.cameraControlId === selectedCameraControl.value &&
        filterItem.commentType === 'camera_comment'
    )

    // find Config Comment
    const filterConfigComment = filterProjectComment.filter(
      (filterItem) =>
        filterItem.cameraControlId === selectedCameraControl.value &&
        filterItem.fscId === selectedTrimValue.value &&
        filterItem.exteriorColorId === selectedExterior.value &&
        filterItem.commentType === 'config_comment'
    )

    // find Trim Comment
    const filterTrimComments = filterProjectComment.filter(
      (filterItem) =>
        filterItem.fscId === selectedTrimValue.value &&
        filterItem.cameraControlId === selectedCameraControl.value &&
        filterItem.commentType === 'trim_comment'
    )
     */

    if (selectedComment[0].cameraControlId !== selectedCameraControl.value) {
      dispatch(
        setCameraControl({
          value: selectedComment[0].cameraControlId,
          id,
          frameNum: selectedComment[0]?.index
        })
      )
    } else {
      // For 360 views only
      if (selectedComment[0]?.index) {
        dispatch(
          setCameraControl({
            value: selectedComment[0].cameraControlId,
            id,
            frameNum: selectedComment[0]?.index
          })
        )
      }
      dispatch(
        customCanvasMatchCurrentScreen(
          convertToDataCanvasSelect(selectedComment)
        )
      )
    }
    // For pano views only
    if (selectedComment[0]?.canvas?.vector3) {
      dispatch(highlightAnnotation({ id: selectedComment[0].canvas.id }))
    }
  }
  return (
    <List className={classes.root}>
      {list &&
        Array.isArray(list) &&
        list.map(({ commentDetail, id }) => {
          const { selectedTrim, selectedCameraControl, message } =
            commentDetail.content
          const { user, createdDate } = commentDetail
          return (
            <React.Fragment key={id}>
              <ListItem
                alignItems='flex-start'
                className={classes.hover}
                onClick={() => handleClick(id)}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{
                      background: '#002856',
                      color: 'white'
                    }}
                  >
                    {user?.charAt(0)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${user} - ${createdDate}`}
                  secondary={
                    <>
                      <Typography
                        component='span'
                        variant='body2'
                        className={classes.inline}
                        color='textPrimary'
                      >
                        {`${id} - ${selectedTrim} ${selectedCameraControl} - `}
                      </Typography>
                      {message}
                    </>
                  }
                />
              </ListItem>
              <Divider variant='inset' component='li' />
            </React.Fragment>
          )
        })}
    </List>
  )
}

ItemsList.propTypes = {
  list: propTypes.array
}

import { useState, useEffect } from 'react'

import {
  HYUNDAI,
  SUBARU,
  GENESIS
} from 'constants/constants'

export const useLightTheme = brand => {
  const [isLightTheme, setLightTheme] = useState(true)

  useEffect(() => {
    if (brand === HYUNDAI || brand === SUBARU) { setLightTheme(true) } else if (brand === GENESIS) { setLightTheme(false) }
  }, [brand])

  return [isLightTheme]
}

import { useEffect, useState } from 'react'

const useDocumentTitle = (title) => {
  const [documentTitle, setDocumentTitle] = useState(title)
  useEffect(() => {
    document.title = title
    setDocumentTitle(title)
  }, [title])

  return [documentTitle, setDocumentTitle]
}

export { useDocumentTitle }

import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import ProtectedRoute from './ProtectedRoute'
import Loading from 'components/common/Loading'

import Admin from 'pages/Admin/Admin'
import AdminNew from 'pages/AdminNew/AdminNew'
import AdminEdit from 'pages/AdminEdit/AdminEdit'

import Login from 'pages/Login/Login'
import Projects from 'pages/Projects/Projects'
import Review from 'pages/Review/Review'

import {
  initializeProjects
} from 'redux/reducers/projects/projectsSlice'

const AppRouter = () => {
  const dispatch = useDispatch()

  const { isSignedIn } = useSelector((state) => state.auth)
  const { isFetching, selectedProject: { id }, isVanityURL } = useSelector(
    (state) => state.projects
  )

  useEffect(() => {
    dispatch(initializeProjects())
  }, [])

  return (
    <Router>
      {isFetching
        ? <Loading />
        : <Switch>
          <Route exact path='/'>
            <Redirect to='/login' replace />
          </Route>
          <Route exact path='/login'>
            <ProtectedRoute isAllowed={!isSignedIn} redirectPath='/projects'>
              <Login />
            </ProtectedRoute>
          </Route>

          <ProtectedRoute isAllowed={isSignedIn}>
            <Route exact path='/projects'>
              {!isVanityURL ? <Projects /> : <Redirect to='/review' replace />}
            </Route>
            <Route exact path='/review'>
              {id ? <Review /> : <Redirect to='/projects' replace />}
            </Route>
            <Route exact path='/admin' component={Admin} />
            <Route exact path='/admin/new' component={AdminNew} />
            <Route exact path='/admin/edit/:id' component={AdminEdit} />
          </ProtectedRoute>

          <Route path='*'>
            <p>404 There is not page with the url</p>
          </Route>
        </Switch>}
    </Router>
  )
}

export default AppRouter

import {
  GENESIS,
  GENESIS_THREE_D_LENGTH,
  HYUNDAI,
  HYUNDAI_THREE_D_LENGTH,
  SUBARU,
  SUBARU_THREE_D_LENGTH
} from '../constants/constants'

export default function getThreeDLenght (brand, cameraControlValue) {
  if ((brand === SUBARU && cameraControlValue === '212052001') || (brand === SUBARU && cameraControlValue === '212052144') || (brand === SUBARU && cameraControlValue === '212051001')) {
    return SUBARU_THREE_D_LENGTH
  } else if (brand === HYUNDAI) {
    return HYUNDAI_THREE_D_LENGTH
  } else if (brand === GENESIS) {
    return GENESIS_THREE_D_LENGTH
  } else {
    return 36
  }
}

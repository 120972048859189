import React from 'react'
import genesisLogo from '../../assets/logos/genesis-logo.png'
import hyundaiLogo from '../../assets/logos/hyundai-logo.png'
import subaruLogo from '../../assets/logos/Subaru_logo.png'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import { ReactComponent as Dash } from '../../assets/icons/Dash.svg'
import { ReactComponent as Add } from '../../assets/icons/Add.svg'
import { ReactComponent as Hide } from '../../assets/icons/Hide.svg'
import { ReactComponent as Menu } from '../../assets/icons/Menu.svg'
import { ReactComponent as Show } from '../../assets/icons/Show.svg'
import { ReactComponent as Home } from '../../assets/icons/Home.svg'
import { ReactComponent as Content } from '../../assets/icons/Content.svg'
import { ReactComponent as ROD } from '../../assets/icons/ROD.svg'
import { ReactComponent as CDN } from '../../assets/icons/CDN.svg'
import { ReactComponent as Garage } from '../../assets/icons/Garage.svg'
import { ReactComponent as AddComment } from '../../assets/icons/AddComment.svg'
import { ReactComponent as HideComment } from '../../assets/icons/HideComment.svg'
import { ReactComponent as HomeLight } from '../../assets/icons/HomeLight.svg'
import { ReactComponent as HomeDark } from '../../assets/icons/HomeDark.svg'
import { ReactComponent as Download } from '../../assets/icons/Download.svg'
import { ReactComponent as Info } from '../../assets/icons/Info.svg'
import threeDotImg from '../../assets/icons/three-dots.png'

export const LogoIcon = Logo
export const GenesisLogoImg = () => <img src={genesisLogo} alt='genesis_logo' />
export const HyundaiLogoImg = () => <img src={hyundaiLogo} alt='hyundai_logo' />
export const SubaruLogoImg = () => <img src={subaruLogo} alt='hyundai_logo' />
export const ThreeDotImg = () => <img src={threeDotImg} alt='three-dots' />
// Icons
export const DashIcon = Dash
export const AddIcon = Add
export const HideIcon = Hide
export const MenuIcon = Menu
export const ShowIcon = Show
export const HomeIcon = Home
export const ContentIcon = Content
export const RODIcon = ROD
export const CDNIcon = CDN
export const GarageIcon = Garage
export const AddCommentIcon = AddComment
export const HideCommentIcon = HideComment
export const HomeDarkIcon = HomeDark
export const HomeLightIcon = HomeLight
export const DownloadIcon = Download
export const InfoIcon = Info

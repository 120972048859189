import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import { postProjectsAPI, putProjectsAPI } from 'redux/reducers/projects/projectAPI'

const verList = [
  { id: '1.0', title: '1.0' },
  { id: '1.1', title: '1.1' },
  { id: '1.2', title: '1.2' },
  { id: '1.3', title: '1.3' },
  { id: '1.4', title: '1.4' },
  { id: '1.5', title: '1.5' },
  { id: '1.6', title: '1.6' },
  { id: '1.7', title: '1.7' },
  { id: '1.8', title: '1.8' },
  { id: '1.9', title: '1.9' },
  { id: '2.0', title: '2.0' },
  { id: '2.1', title: '2.1' },
  { id: '2.2', title: '2.2' },
  { id: '2.3', title: '2.3' },
  { id: '2.4', title: '2.4' }
]

const verBaseList = [
  { id: 'baseline', title: 'baseline' },
  { id: '1.0', title: '1.0' },
  { id: '1.1', title: '1.1' },
  { id: '1.2', title: '1.2' },
  { id: '1.3', title: '1.3' },
  { id: '1.4', title: '1.4' },
  { id: '1.5', title: '1.5' },
  { id: '1.6', title: '1.6' },
  { id: '1.7', title: '1.7' },
  { id: '1.8', title: '1.8' },
  { id: '1.9', title: '1.9' },
  { id: '2.0', title: '2.0' },
  { id: '2.1', title: '2.1' },
  { id: '2.2', title: '2.2' },
  { id: '2.3', title: '2.3' },
  { id: '2.4', title: '2.4' }
]

const statusList = [
  { id: 'draft', title: 'draft' },
  { id: 'active', title: 'active' },
  { id: 'completed', title: 'completed' },
  { id: 'archived', title: 'archived' }
]

const tabs = [
  { name: 'cameras' },
  { name: 'extColors' },
  { name: 'trim' },
  { name: 'intColors' }
]

const Project = ({ data, saved, setSaved, register }) => {
  return (
    <>
      <div className='space-y-8 divide-y divide-gray-200'>
        <div>
          <div className='flex justify-between'>
            <div>
              <h3 className='text-lg font-medium leading-6 text-white'>Project Details</h3>
            </div>
            <div>
              <SaveButton saved={saved} setSaved={setSaved} data={data} />
            </div>
          </div>

          <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
            <input
              type='hidden'
              name='obj_id'
              id='obj_id'
              className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
              {...register('obj_id')}
            />
            <input
              type='hidden'
              name='id'
              id='id'
              className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
              {...register('id')}
            />
            <input
              type='hidden'
              name='created'
              id='created'
              className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
              {...register('created')}
            />

            <div className='sm:col-span-3'>
              <label htmlFor='name' className='block text-sm font-medium text-white'>
                Name
              </label>
              <div className='mt-1 flex rounded-md shadow-sm'>
                <input
                  type='text'
                  name='name'
                  id='name'
                  className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
                  required
                  {...register('name', { required: true })}
                />
              </div>
            </div>

            <div className='sm:col-span-3'>
              <label htmlFor='title' className='block text-sm font-medium text-white'>
                Title
              </label>
              <div className='mt-1 flex rounded-md shadow-sm'>
                <input
                  type='text'
                  name='title'
                  id='title'
                  className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
                  required
                  {...register('title', { required: true })}
                />
              </div>
            </div>

            <div className='sm:col-span-3'>
              <label htmlFor='description' className='block text-sm font-medium text-white'>
                Desc
              </label>
              <div className='mt-1 flex rounded-md shadow-sm'>
                <input
                  type='text'
                  name='description'
                  id='description'
                  className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
                  required
                  {...register('description', { required: true })}
                />
              </div>
            </div>

            <div className='sm:col-span-3'>
              <label htmlFor='domain' className='block text-sm font-medium text-white'>
                Domain Name
              </label>
              <div className='mt-1 flex rounded-md shadow-sm'>
                <input
                  type='text'
                  name='domain'
                  id='domain'
                  placeholder='example.com'
                  className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
                  required
                  {...register('domain', { required: true })}
                />
              </div>
            </div>

            <div className='sm:col-span-3'>
              <label htmlFor='brand' className='block text-sm font-medium text-white'>
                Brand
              </label>
              <div className='mt-1 flex rounded-md shadow-sm'>
                <input
                  type='text'
                  name='brand'
                  id='brand'
                  className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
                  required
                  {...register('brand', { required: true })}
                />
              </div>
            </div>

            <div className='sm:col-span-2'>
              <label htmlFor='year' className='block text-sm font-medium text-white'>
                Year
              </label>
              <div className='mt-1 flex rounded-md shadow-sm'>
                <input
                  type='text'
                  name='year'
                  id='year'
                  className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
                  required
                  {...register('year', { required: true })}
                />
              </div>
            </div>

            <div className='sm:col-span-1'>
              <label htmlFor='annotationEnabled' className='block text-sm font-medium text-white'>
                Annotation
              </label>
              <div className='mt-1 rounded-md shadow-sm'>
                <input
                  type='checkbox'
                  name='annotationEnabled'
                  id='annotationEnabled'
                  className='block min-w-0 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
                  {...register('annotationEnabled')}
                />
              </div>
            </div>

            <div className='sm:col-span-3'>
              <label className='block text-sm font-medium text-white'>
                Live
              </label>
              <div className='flex justify-between'>
                <div className='mt-1 flex rounded-md shadow-sm'>
                  <input
                    type='text'
                    name='live_version'
                    id='live_version'
                    placeholder='1.0'
                    required
                    className='block min-w-0 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
                    {...register('live.assetsVersion', { required: true })}
                  />
                </div>
                <div className='mt-1 flex rounded-md shadow-sm'>
                  <select
                    name='live_status'
                    id='live_status'
                    className='block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
                    defaultValue={statusList[0].id}
                    required
                    {...register('live.status', { required: true })}
                  >
                    {statusList.map((item) => (
                      <option key={item.id} value={item.id}>{item.title}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className='sm:col-span-3'>
              <label className='block text-sm font-medium text-white'>
                Preview
              </label>
              <div className='flex justify-between'>
                <div className='mt-1 flex rounded-md shadow-sm'>
                  <input
                    type='text'
                    name='preview_version'
                    id='preview_version'
                    placeholder='1.0'
                    required
                    className='block min-w-0 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
                    {...register('preview.assetsVersion', { required: true })}
                  />
                </div>
                <div className='mt-1 flex rounded-md shadow-sm'>
                  <select
                    name='preview_status'
                    id='preview_status'
                    className='block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
                    defaultValue={statusList[0].id}
                    required
                    {...register('preview.status', { required: true })}
                  >
                    {statusList.map((item) => (
                      <option key={item.id} value={item.id}>{item.title}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function CameraItem ({ register, control, idx, item, append, remove }) {
  return (
    <>
      <tr key={item.id}>
        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6'>
          <input
            type='text'
            name={`configurations.cameras.${idx}.title`}
            id={`configurations.cameras.${idx}.title`}
            defaultValue={item?.title}
            className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
            {...register(`configurations.cameras.${idx}.title`)}
          />
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <input
            type='text'
            name={`configurations.cameras.${idx}.value`}
            id={`configurations.cameras.${idx}.value`}
            defaultValue={item?.value}
            className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
            {...register(`configurations.cameras.${idx}.value`)}
          />
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <select
            type='text'
            name={`configurations.cameras.${idx}.version`}
            id={`configurations.cameras.${idx}.version`}
            defaultValue={item?.version}
            className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
            {...register(`configurations.cameras.${idx}.version`)}
          >
            {verBaseList.map((item) => (
              <option key={item.id} value={item.id}>{item.title}</option>
            ))}
          </select>
        </td>
        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
          <div className='flex justify-end'>
            <div className='ml-4 text-indigo-600 hover:text-indigo-100 curosr-pointer' onClick={() => { remove(idx) }}>
              Remove
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}

function CameraTable ({ register, control }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'configurations.cameras'
  })

  return (
    <div className='px-4'>
      <div className='flex flex-col'>
        <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full bg-black divide-y divide-gray-300'>
                <thead className=''>
                  <tr>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6'>
                      Title
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100'>
                      Value
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100' />
                    <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                      <div className='flex justify-end'>
                        <div className='text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { append() }}>
                          Add
                        </div>
                        <div className='ml-4 text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { remove(-1) }}>
                          Remove
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                  {fields && fields.map((item, idx) => (
                    <CameraItem key={idx} idx={idx} item={item} control={control} register={register} append={append} remove={remove} />
                  ))}
                  <tr>
                    <td scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6' />
                    <td scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100' />
                    <td scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100' />
                    <td scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                      <div className='flex justify-end'>
                        <div className='text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { append() }}>
                          Add
                        </div>
                        <div className='ml-4 text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { remove(-1) }}>
                          Remove
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ExtItem ({ register, control, idx, item, append, remove }) {
  return (
    <>
      <tr key={item.id}>
        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6'>
          <input
            type='text'
            name={`configurations.extColors.${idx}.id`}
            id={`configurations.extColors.${idx}.id`}
            defaultValue={item?.id}
            className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
            {...register(`configurations.extColors.${idx}.id`)}
          />
        </td>
        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6'>
          <input
            type='text'
            name={`configurations.extColors.${idx}.name`}
            id={`configurations.extColors.${idx}.name`}
            defaultValue={item?.name}
            className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
            {...register(`configurations.extColors.${idx}.name`)}
          />
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <input
            type='text'
            name={`configurations.extColors.${idx}.color`}
            id={`configurations.extColors.${idx}.color`}
            defaultValue={item?.color}
            className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
            {...register(`configurations.extColors.${idx}.color`)}
          />
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <input
            type='text'
            name={`configurations.extColors.${idx}.colorCheck`}
            id={`configurations.extColors.${idx}.colorCheck`}
            defaultValue={item?.colorCheck}
            className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
            {...register(`configurations.extColors.${idx}.colorCheck`)}
          />
        </td>

        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <input
            type='checkbox'
            name={`configurations.extColors.${idx}.default`}
            id={`configurations.extColors.${idx}.default`}
            defaultValue={item?.default}
            className='rounded-md'
            {...register(`configurations.extColors.${idx}.default`)}
          />
        </td>
        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
          <div className='flex justify-end'>
            <div className='ml-4 text-indigo-600 hover:text-indigo-100 curosr-pointer' onClick={() => { remove(idx) }}>
              Remove
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}

function ExtTable ({ register, control }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'configurations.extColors'
  })

  return (
    <div className='px-4'>
      <div className='flex flex-col'>
        <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full bg-black divide-y divide-gray-300'>
                <thead className=''>
                  <tr>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6'>
                      ID
                    </th>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6'>
                      Name
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100'>
                      Color
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100'>
                      Color Check
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100'>
                      Default
                    </th>
                    <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                      <div className='flex justify-end'>
                        <div className='text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { append() }}>
                          Add
                        </div>
                        <div className='ml-4 text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { remove(-1) }}>
                          Remove
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                  {fields && fields.map((item, idx) => (
                    <ExtItem key={idx} idx={idx} item={item} control={control} register={register} append={append} remove={remove} />
                  ))}
                  <tr>
                    <td scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6' />
                    <td scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6' />
                    <td scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100' />
                    <td scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100' />
                    <td scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100' />
                    <td scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                      <div className='flex justify-end'>
                        <div className='text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { append() }}>
                          Add
                        </div>
                        <div className='ml-4 text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { remove(-1) }}>
                          Remove
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function FSCItem ({ register, control, idx, item, append, remove }) {
  return (
    <>
      <tr key={item.id}>
        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6'>
          <input
            type='text'
            name={`configurations.fscs.${idx}.id`}
            id={`configurations.fscs.${idx}.id`}
            defaultValue={item?.id}
            className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
            {...register(`configurations.fscs.${idx}.id`)}
          />
        </td>
        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6'>
          <input
            type='text'
            name={`configurations.fscs.${idx}.title`}
            id={`configurations.fscs.${idx}.title`}
            defaultValue={item?.title}
            className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
            {...register(`configurations.fscs.${idx}.title`)}
          />
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <input
            type='text'
            name={`configurations.fscs.${idx}.value`}
            id={`configurations.fscs.${idx}.value`}
            defaultValue={item?.value}
            className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
            {...register(`configurations.fscs.${idx}.value`)}
          />
        </td>
        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
          <div className='flex justify-end'>
            <div className='ml-4 text-indigo-600 hover:text-indigo-100 curosr-pointer' onClick={() => { remove(idx) }}>
              Remove
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}

function FSCTable ({ register, control }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'configurations.fscs'
  })

  return (
    <div className='px-4'>
      <div className='flex flex-col'>
        <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full bg-black divide-y divide-gray-300'>
                <thead className=''>
                  <tr>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6'>
                      ID
                    </th>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6'>
                      Title
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100'>
                      Value
                    </th>
                    <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                      <div className='flex justify-end'>
                        <div className='text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { append() }}>
                          Add
                        </div>
                        <div className='ml-4 text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { remove(-1) }}>
                          Remove
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                  {fields && fields.map((item, idx) => (
                    <FSCItem key={idx} idx={idx} item={item} control={control} register={register} append={append} remove={remove} />
                  ))}
                  <tr>
                    <td scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6' />
                    <td scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6' />
                    <td scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100' />
                    <td scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                      <div className='flex justify-end'>
                        <div className='text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { append() }}>
                          Add
                        </div>
                        <div className='ml-4 text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { remove(-1) }}>
                          Remove
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function IntItem ({ register, control, idx, item, append, remove }) {
  return (
    <>
      <tr key={item.id}>
        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6'>
          <input
            type='text'
            name={`configurations.intColors.${idx}.id`}
            id={`configurations.intColors.${idx}.id`}
            defaultValue={item?.id}
            className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
            {...register(`configurations.intColors.${idx}.id`)}
          />
        </td>
        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6'>
          <input
            type='text'
            name={`configurations.intColors.${idx}.name`}
            id={`configurations.intColors.${idx}.name`}
            defaultValue={item?.name}
            className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
            {...register(`configurations.intColors.${idx}.name`)}
          />
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <input
            type='text'
            name={`configurations.intColors.${idx}.color`}
            id={`configurations.intColors.${idx}.color`}
            defaultValue={item?.color}
            className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
            {...register(`configurations.intColors.${idx}.color`)}
          />
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <input
            type='text'
            name={`configurations.intColors.${idx}.colorCheck`}
            id={`configurations.intColors.${idx}.colorCheck`}
            defaultValue={item?.colorCheck}
            className='block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-black'
            {...register(`configurations.intColors.${idx}.colorCheck`)}
          />
        </td>

        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
          <input
            type='checkbox'
            name={`configurations.intColors.${idx}.default`}
            id={`configurations.intColors.${idx}.default`}
            defaultValue={item?.default}
            className='rounded-md'
            {...register(`configurations.intColors.${idx}.default`)}
          />
        </td>
        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
          <div className='flex justify-end'>
            <div className='ml-4 text-indigo-600 hover:text-indigo-100 curosr-pointer' onClick={() => { remove(idx) }}>
              Remove
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}

function IntTable ({ register, control }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'configurations.intColors'
  })

  return (
    <div className='px-4'>
      <div className='flex flex-col'>
        <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='inline-block min-w-full py-2 align-middle'>
            <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
              <table className='min-w-full bg-black divide-y divide-gray-300'>
                <thead className=''>
                  <tr>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6'>
                      ID
                    </th>
                    <th scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6'>
                      Name
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100'>
                      Color
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100'>
                      Color Check
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100'>
                      Default
                    </th>
                    <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                      <div className='flex justify-end'>
                        <div className='text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { append() }}>
                          Add
                        </div>
                        <div className='ml-4 text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { remove(-1) }}>
                          Remove
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                  {fields && fields.map((item, idx) => (
                    <IntItem key={idx} idx={idx} item={item} control={control} register={register} append={append} remove={remove} />
                  ))}
                  <tr>
                    <td scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6' />
                    <td scope='col' className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6' />
                    <td scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100' />
                    <td scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100' />
                    <td scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-100' />
                    <td scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                      <div className='flex justify-end'>
                        <div className='text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { append() }}>
                          Add
                        </div>
                        <div className='ml-4 text-indigo-600 hover:text-indigo-100 cursor-pointer' onClick={() => { remove(-1) }}>
                          Remove
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Tabs = ({ register, control, tab, setTab }) => {
  return (
    <>
      <div>
        <div className='sm:hidden'>
          <label htmlFor='tabs' className='sr-only'>
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id='tabs'
            name='tabs'
            className='block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
            defaultValue={tabs.find((item) => tab === item.name).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className='hidden sm:block'>
          <div className='border-b border-gray-200'>
            <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
              {tabs.map((item) => (
                <div
                  key={item.name}
                  onClick={() => {
                    setTab(item.name)
                  }}
                  className={clsx(
                    tab === item.name
                      ? 'border-indigo-500 text-white'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                  )}
                  aria-current={tab === item.name ? 'page' : undefined}
                >
                  {item.name}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div>
        {tab === 'cameras' && <CameraTable control={control} register={register} />}
        {tab === 'extColors' && <ExtTable control={control} register={register} />}
        {tab === 'trim' && <FSCTable control={control} register={register} />}
        {tab === 'intColors' && <IntTable control={control} register={register} />}
      </div>
    </>
  )
}

const SaveButton = ({ data, saved, setSaved }) => {
  useEffect(() => {
    if (data && saved) {
      setTimeout(() => {
        console.log('saved off')
        setSaved(false)
      }, 5000)
    }
  }, [saved])

  return (
    <>
      <div className='flex justify-between items-center'>
        {saved && <div>Saved!</div>}
        <button
          type='submit'
          className='ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        >
          {!data && 'Create'}
          {data && 'Save'}
        </button>
      </div>
    </>
  )
}

const Form = ({ data }) => {
  const history = useHistory()
  const { reset, control, register, handleSubmit } = useForm()
  const [tab, setTab] = useState('cameras')
  const [saved, setSaved] = useState(false)

  const onSubmit = (formData) => {
    const now = new Date()
    const data = {
      id: formData.id,
      domain: formData.domain,
      name: formData.name,
      title: formData.title,
      description: formData.description,
      brand: formData.brand,
      year: formData.year,
      annotationEnabled: formData.annotationEnabled,
      live: formData.live,
      preview: formData.preview,
      configurations: formData.configurations,
      created: formData.created,
      lastUpdated: ''
    }
    const obj = {
      obj_id: formData?.obj_id,
      path: `/projects/${formData.domain}`,
      data
    }

    if (obj.obj_id === '') {
      obj.data.id = `project-${formData.name.toLowerCase()}`
      obj.data.created = `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`
      obj.data.lastUpdated = `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`
      postProjectsAPI(obj).then((resp) => {
        history.push(`/admin/edit/${data.domain}`)
      })
    } else {
      obj.data.lastUpdated = `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`
      putProjectsAPI(obj).then((resp) => {
        setSaved(true)
      })
    }
  }

  useEffect(() => {
    reset(data)
  }, [reset, data])

  return (
    <>
      <div className='w-full w-9/12 mx-auto pb-24'>
        <form onSubmit={handleSubmit(onSubmit)} className='space-y-8'>
          <Project data={data} saved={saved} setSaved={setSaved} register={register} />
          <Tabs register={register} tab={tab} setTab={setTab} control={control} />
          <div className='pt-5'>
            <div className='flex justify-end'>
              <SaveButton saved={saved} setSaved={setSaved} data={data} />
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default Form

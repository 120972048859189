import React, { useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'

import './Configure.scss'

import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/Check'

import { signOut } from 'redux/reducers/auth/authSlice'
import { setSelectedProject, setCollection } from 'redux/reducers/projects/projectsSlice'
import {
  setFlat,
  setCameraControl,
  setSelectedTrimValue,
  setSelectedInterior,
  setSelectedExterior,
  clearState,
  fetchTrimComments,
  fetchProjectComments,
  setImagesFromCDN
} from 'redux/reducers/ui/uiSlice'

import { useDocumentTitle } from 'hooks/useDocumentTitle'
import { useLightTheme } from 'hooks/useLightTheme'

import Select from 'components/common/Select/Select'
import Button from 'components/common/Button/Button'

const Configure = () => {
  const dispatch = useDispatch()
  const {
    selectedProject: {
      id: projectID, brand, name: productName, version, year,
      description, title,
      configurations: { fscs, extColors, intColors, cameras }
    },
    collection
  } = useSelector(
    (state) => state.projects
  )
  const [isLightTheme] = useLightTheme(brand)

  useDocumentTitle(title)

  const {
    configre: { flat, cameraControls, selectedInterior, selectedExterior }
  } = useSelector((state) => state.ui)

  const [trims, setTrims] = useState({
    value: '',
    name: ''
  })

  useEffect(() => {
    if (fscs?.length > 0) {
      const defaultTrim = fscs.find((fsc) => fsc.default === true)
      const trim = defaultTrim || fscs[0]
      setTrims({
        value: trim.value,
        name: trim.title
      })

      dispatch(setSelectedTrimValue({ name: trim.title, value: trim.value }))
      // dispatch(fetchProjectComments({ projectID }))
    }
  }, [fscs])

  const handleChangeTrim = (event) => {
    dispatch(setCollection([]))
    setTrims({
      value: event.target.value,
      name: event?.nativeEvent?.srcElement?.textContent || ''
    })
    dispatch(setSelectedTrimValue({ name: trims.name, value: trims.value }))
    // dispatch(fetchProjectComments({ projectID }))
  }

  const handleChangeFlat = (event) => {
    // dispatch(clearState({}))
    dispatch(setCollection([]))
    dispatch(setFlat(event.target.checked))
  }

  const handleChangeExteriorColor = (item) => {
    dispatch(setSelectedExterior({ data: selectedExterior.data, value: item.id }))
    // dispatch(fetchProjectComments({ projectID }))
  }

  const handleChangeInteriorColor = (item) => {
    dispatch(setSelectedInterior({ data: selectedInterior.data, value: item.id }))
    // dispatch(fetchProjectComments({ projectID }))
  }

  const handleChangeCameraControl = (event) => {
    dispatch(setCameraControl({ value: event.target.value }))
    // dispatch(fetchTrimComments({ projectID }))
  }

  const getDefaultValue = (values, valueType) => {
    const controlValue = valueType === 'extColor' ? selectedExterior.value : valueType === 'intColor' ? selectedInterior.value : valueType === 'cam' ? cameraControls.value : null
    const matched = values.filter((item) => item[valueType === 'cam' ? 'value' : 'id'] === controlValue)
    if (matched.length) {
      return controlValue
    }
    if (values.find((state) => state.default === true)) {
      return values.find((state) => state.default === true)[valueType === 'cam' ? 'value' : 'id']
    } else {
      return values[0][valueType === 'cam' ? 'value' : 'id']
    }
  }

  const handleLogout = async () => {
    const action = signOut()
    dispatch(action)
    // await authService.logout()
  }

  const resetExtColors = () => {
    const newExtColors = extColors?.filter(
      (extColor) =>
        collection.filter(
          (item) =>
            item?.images?.filter((img) => img.endsWith(cameraControls.value))
              .length && item.extcolor === extColor.id
        ).length
    )
    if (newExtColors && newExtColors.length) {
      dispatch(setSelectedExterior({ value: getDefaultValue(newExtColors, 'extColor'), data: newExtColors }))
      // dispatch(fetchProjectComments({ projectID }))
    }
  }

  const resetIntColors = () => {
    const newIntColors = intColors?.filter(
      (intColor) =>
        collection.filter(
          (item) =>
            item.intcolor === intColor.id &&
            item.extcolor === selectedExterior.value &&
            item.images.filter((img) => img.endsWith(cameraControls.value))
              .length
        ).length
    )
    if (newIntColors && newIntColors.length) {
      dispatch(setSelectedInterior({ value: getDefaultValue(newIntColors, 'intColor'), data: newIntColors }))
      // dispatch(fetchProjectComments({ projectID }))
    }
  }

  const renderCars = () => {
    if (flat) {
      dispatch(
        setImagesFromCDN(
          collection
        )
      )
    } else {
      dispatch(
        setImagesFromCDN(
          collection.filter(
            (item) =>
              item.latest_version === version &&
              item.extcolor === selectedExterior.value &&
              item.intcolor === selectedInterior.value
          )
        )
      )
    }
  }

  useMemo(() => {
    cameraControls.value && resetExtColors()
  }, [collection, cameraControls.value])

  useMemo(() => {
    selectedExterior.value && resetIntColors()
  }, [selectedExterior])

  useMemo(() => {
    selectedInterior.value && renderCars()
  }, [selectedInterior])

  useMemo(() => {
    flat && renderCars()
  }, [flat, collection])

  const fetchProjectData = () => {
    try {
      if (cameras?.length > 0 && trims.value) {
        fetch(
          `${process.env.REACT_APP_HMA_CDN_URL}&product__name=${productName}&fsc=${trims.value}&year=${year}&flat=${flat}`
        ).then((res) => {
          res.json().then((data) => {
            const newCollection = data.results.filter(
              (item) => item.latest_version === version
            )
            const allImages = newCollection.reduce(
              (prev, current) => prev.concat(current.images),
              []
            )

            // filter by camera
            if (allImages.length > 0) {
              let newCams = []
              if (flat) {
                newCams = cameras.filter(
                  (cam) => allImages.filter((img) => img.endsWith(cam.value + '.flat')).length
                )
              } else {
                newCams = cameras.filter(
                  (cam) => allImages.filter((img) => img.endsWith(cam.value)).length
                )
              }
              dispatch(
                setCameraControl({
                  data: newCams,
                  value: newCams.length && getDefaultValue(newCams, 'cam')
                })
              )
            }
            dispatch(setCollection(newCollection))
          })
        })
      }
    } catch (error) {

    }
  }

  useEffect(() => fetchProjectData(), [
    trims.value,
    flat
  ])

  useEffect(() => {
    return function cleanup () {
      dispatch(clearState({}))
    }
  }, [])

  return (
    <div className={clsx('configure', isLightTheme ? 'lightTheme' : '')}>
      <div className='configure_header'>
        <label>{description || 'CMS'}</label>
      </div>
      <div className='configure_options'>
        <Select
          label='Trims'
          className='configure_trims'
          value={trims.value}
          name={trims.title}
          data={fscs}
          handleChange={handleChangeTrim}
          lightTheme={isLightTheme}
        />

        <div className='configure_colors'>
          <div className='configure_exteriorColors_title'>
            <p>Exterior Colors</p>
          </div>
          <div className='configure_exteriorColors_multiple'>
            <Grid container>
              {selectedExterior.data.map((item) => (
                <Grid key={item.id} item xs={4} sm={4}>
                  <Button
                    className='btnExteriorColor'
                    style={{ background: `${item.color}` }}
                    icon={
                      <CheckIcon
                        style={{
                          color: `${item.colorCheck}`
                        }}
                      />
                    }
                    active={item.id === selectedExterior.value}
                    onClick={() => handleChangeExteriorColor(item)}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
          <div className='configure_exteriorColors_nameColor'>
            <p>
              {
                selectedExterior.data.find(
                  (item) => item.id === selectedExterior.value
                )?.name
              }{' '}
            </p>
          </div>
          <div className='configure_interiorColors_title'>
            <p>Interior Colors</p>
          </div>
          <div className='configure_interiorColors_multiple'>
            <Grid container>
              {selectedInterior.data.map((item) => (
                <Grid key={item.id} item xs={4} sm={4}>
                  <Button
                    className='btnInteriorColor'
                    style={{ background: `${item.color}` }}
                    icon={
                      <CheckIcon
                        style={{
                          color: `${item.colorCheck}`
                        }}
                      />
                    }
                    active={item.id === selectedInterior.value}
                    onClick={() => handleChangeInteriorColor(item)}
                    data={item}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
          <div className='configure_interiorColors_nameColor'>
            <p>
              {
                selectedInterior.data.find(
                  (item) => item.id === selectedInterior.value
                )?.name
              }{' '}
            </p>
          </div>
        </div>
        <Select
          className='configure_cameras'
          label='Views'
          value={cameraControls.value}
          data={cameraControls.data}
          handleChange={handleChangeCameraControl}
          lightTheme={isLightTheme}
        />

        {/*<div className='configure_flat'>
          <div className='configure_flat_title'>
            <p>Flatten Image</p> <input name='flat' type='checkbox' defaultChecked={flat} onChange={handleChangeFlat} />
          </div>
        </div>*/}
      </div>

      <div className='configure_logout'>
        <p>
          Digitalgiant <span onClick={handleLogout}>Logout</span>
        </p>
      </div>
    </div>
  )
}

export default withRouter(Configure)

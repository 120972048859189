import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import 'components/common/Pagination/index.scss'

const Pagination = ({ page = 1, limit = 10, total = 0 }) => {
  const history = useHistory()

  let numPage = 1
  if (page > 0) {
    numPage = parseInt(page)
  }
  let numLimit = 10
  if (limit > 0) {
    numLimit = parseInt(limit)
  }

  const onPrevious = () => {
    if (numPage !== 1) {
      history.push(`/projects/?page=${numPage - 1}&limit=${numLimit}`)
    }
  }

  const onNext = () => {
    if ((numPage * numLimit) < total) {
      history.push(`/projects/?page=${numPage + 1}&limit=${numLimit}`)
    }
  }

  return (
    <>
      <div className='pagination'>
        <div className={clsx('button', numPage === 1 ? 'hide' : '')} onClick={onPrevious}>Previous</div>
        <div>Total: {total} / Page: {numPage}</div>
        <div className={clsx('button', ((numPage) * numLimit) > total ? 'hide' : '')} onClick={onNext}>Next</div>
      </div>
    </>
  )
}

export default Pagination

import React, { useMemo, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'

import ThreeDView from 'components/ThreeDView'
import PanoView from 'components/PanoView'
import Slider from 'components/Slider/Slider'
import { slicer } from 'components/CameraRender/data'
import Comments from 'components/Comments/Comments'
import ItemsList from 'components/common/List/List'
import getThreeDLenght from '../../utils/getThreeDLength'

const CameraRender = ({
  activeTool,
  setActiveTool,
  brand,
  filterComments
}) => {
  const {
    slider: { data },
    configre: {
      flat,
      cameraControls,
      showComments
    }
  } = useSelector((state) => state.ui)

  const [loading, setLoading] = useState(true)
  const [cameraIndex, setCameraIndex] = useState(0)
  const [camTitle, setCamTitle] = useState(null)

  const allImages = data.reduce(
    (prev, current) => prev.concat(current.images),
    []
  )

  useMemo(() => {
    if (cameraControls?.data?.length) {
      const { title } = cameraControls.data.filter(
        (cam) => cam.value === cameraControls.value
      )[0]
      setCamTitle(title)
    }
  }, [cameraControls])

  useMemo(() => {
    let val = cameraControls.value
    if (flat) {
      val = val + '.flat'
    }
    const cameraIndex = allImages.findIndex((img) =>
      img.endsWith(val)
    )
    if (cameraIndex > -1) {
      setCameraIndex(cameraIndex)
      setLoading(false)
    }
  }, [data])

  if (loading || !camTitle) {
    return (
      <div className='single-view'>
        <CircularProgress />
      </div>
    )
  }

  if (camTitle.toLowerCase().includes('360')) {
    const threeDLength = getThreeDLenght(brand, cameraControls.value)

    return (
      <ThreeDView
        activeTool={activeTool}
        setActiveTool={setActiveTool}
        images={allImages.slice(cameraIndex, cameraIndex + threeDLength)}
        filterComments={filterComments}
        showComments={showComments}
      />
    )
  } else if (camTitle.toLowerCase().includes('pano')) {
    return (
      <PanoView
        activeTool={activeTool}
        src={allImages[cameraIndex]}
        filterComments={filterComments}
        showComments={showComments}
        setActiveTool={setActiveTool}
      />
    )
  } else {
    return (
      <>
        <div className='single-car'>
          <Slider
            imageSource={`${allImages[cameraIndex]}`}
            slicer={slicer}
            setActiveTool={setActiveTool}
          />
          {showComments && (
            <div className='show-comment'>
              <Comments
                title='COMMENTS'
                details={<ItemsList list={filterComments} />}
              />
            </div>
          )}
        </div>
      </>
    )
  }
}

CameraRender.propTypes = {
  activeTool: propTypes.string,
  setActiveTool: propTypes.func,
  brand: propTypes.string,
  filterComments: propTypes.any
}

export default CameraRender

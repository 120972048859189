import { Redirect } from 'react-router-dom'

const ProtectedRoute = ({
  isAllowed,
  redirectPath = '/login',
  children
}) => {
  if (!isAllowed) {
    return <Redirect to={redirectPath} replace />
  }

  return children
}

export default ProtectedRoute

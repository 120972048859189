import React, { useMemo } from 'react'
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined'
import SpeakerNotesOffOutlinedIcon from '@material-ui/icons/SpeakerNotesOffOutlined'
import { clickMenuPano, setShowComments } from 'redux/reducers/ui/uiSlice'
import { clickMenu } from 'redux/reducers/canvas/canvasSlice'
import { useDispatch, useSelector } from 'react-redux'
import propTypes from 'prop-types'

function CommentStatusTool ({ setFilterComments }) {
  const dispatch = useDispatch()
  const {
    configre: {
      showComments,
      cameraControls,
      selectedTrimValue,
      selectedExterior
    },
    saveComments
  } = useSelector((state) => state.ui)
  const { selectedProject } = useSelector(
    (state) => state.projects
  )
  const { id: projectID } = selectedProject

  const onCommentShowClick = () => {
    if (showComments) {
      dispatch(setShowComments(false))
      dispatch(clickMenu('HIDE_MENU'))
      dispatch(clickMenuPano('HIDE_MENU'))
    } else {
      dispatch(setShowComments(true))
      dispatch(clickMenu('SHOW_MENU'))
      dispatch(clickMenuPano('SHOW_MENU'))
    }
  }

  useMemo(() => {
    // find comment on selected project
    const filterProjectComment = saveComments.filter(
      (projectId) => projectId.projectId === projectID
    )

    // find Color Comment
    const filterColorComments = filterProjectComment.filter(
      (filterItem) =>
        filterItem.exteriorColorId === selectedExterior.value &&
        filterItem.commentType === 'color_comment'
    )

    // find Camera Comment
    const filterCameraComment = filterProjectComment.filter(
      (filterItem) =>
        filterItem.cameraControlId === cameraControls.value &&
        filterItem.commentType === 'camera_comment'
    )

    // find Config Comment
    const filterConfigComment = filterProjectComment.filter(
      (filterItem) =>
        filterItem.fscId === selectedTrimValue.value &&
        filterItem.exteriorColorId === selectedExterior.value &&
        filterItem.commentType === 'config_comment'
    )

    // find Trim Comment
    const filterTrimComments = filterProjectComment.filter(
      (filterItem) =>
        filterItem.fscId === selectedTrimValue.value &&
        filterItem.commentType === 'trim_comment'
    )

    const filterComments = filterCameraComment.concat(
      filterConfigComment,
      filterColorComments,
      filterTrimComments
    )
    setFilterComments(filterComments)
  }, [saveComments])

  return (
    <div
      className='viewport_bar_icon'
      onClick={onCommentShowClick}
    >
      {showComments
        ? (
          <ChatOutlinedIcon />
          )
        : (
          <SpeakerNotesOffOutlinedIcon />
          )}
    </div>
  )
}

CommentStatusTool.propTypes = {
  setFilterComments: propTypes.func
}

export default CommentStatusTool

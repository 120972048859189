import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import './Projects.scss'

import { listProjectsAPI } from 'redux/reducers/projects/projectAPI'
import { setSelectedProject, setProjects } from 'redux/reducers/projects/projectsSlice'
import { projectColumns } from 'pages/Projects/ProjectColumns'
import PageHeader from 'components/common/PageHeader/PageHeader'
import Table from 'components/common/Table/Table'

function useQuery () {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Projects = () => {
  const history = useHistory()
  const { allProjects, total } = useSelector(
    (state) => state.projects
  )
  const dispatch = useDispatch()
  const query = useQuery()
  const page = query.get('page') !== null ? parseInt(query.get('page')) : 1
  const limit = query.get('limit') !== null ? parseInt(query.get('limit')) : 100

  const handleClickProject = (e, columnName, itemRow) => {
    dispatch(setSelectedProject({
      ...itemRow,
      version: itemRow[columnName].assetsVersion,
      info: itemRow.moreInfo?.[columnName],
      flat: false
    }))
    history.push('/review')
  }

  const onPage = () => {
    if (page && limit) {
      listProjectsAPI({ page, limit }).then((resp) => {
        dispatch(setProjects([]))
        dispatch(setProjects(resp.project))
      })
    }
  }

  useEffect(() => {
    onPage()
  }, [dispatch, page, limit])

  return (
    <>
      <PageHeader />
      <div className='projects'>
        <Table
          className='dashboard_tableProjects'
          columns={projectColumns}
          dataSource={allProjects || []}
          onAction={handleClickProject}
          total={total}
          page={page}
          limit={limit}
          hover
          hoverClickDisableAtRow={{
            columnName: 'live.status',
            value: 'development'
          }}
        />
      </div>
    </>
  )
}

export default Projects

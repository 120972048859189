import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import { LogoIcon } from 'components/icons/icons'
import 'components/common/PageHeader/PageHeader.scss'

const PageHeader = () => {
  const [userData, setUserData] = useState({})

  useEffect(() => {
    setUserData(JSON.parse(window.localStorage.getItem('userData')))
  }, [])

  return (
    <div className='pageHeader'>
      <div className='pageHeader_logo'>
        <LogoIcon width='100%' />
      </div>
      <div className='pageHeader_userDetails'>
        <Typography variant='subtitle2' gutterBottom>
          {userData?.fname} {userData?.lname}
        </Typography>
      </div>
    </div>
  )
}

export default PageHeader

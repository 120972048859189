import React, { useEffect, useState } from 'react'

// local
import PageHeader from 'components/common/PageHeader/PageHeader'
import Form from 'components/Admin/Form'
import { getProjectByDomainAPI } from 'redux/reducers/projects/projectAPI'
import './Admin.css'

const AdminEdit = (router) => {
  const id = router.match.params.id
  const [data, setData] = useState({})

  // projects
  useEffect(() => {
    getProjectByDomainAPI(id).then((data) => {
      if (data && data.length > 0) {
        setData(data[0])
      }
    })
  }, [id])

  return (
    <>
      <PageHeader />
      <div className='admin'>
        <div className='p-6 max-w-7xl mx-auto'>
          <div className='w-full'>
            <Form data={data} />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminEdit

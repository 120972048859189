import { useEffect, useMemo, useState } from 'react'

function useAspectRatio (containerRef, imgSource, elementId) {
  const [aspectRatio, setAspectRatio] = useState({
    width: 0,
    height: 0,
    ratio: 1
  })

  useMemo(() => {
    const doc = document.getElementById(elementId)
    if (aspectRatio.width && doc) {
      doc.style.width = `${aspectRatio.width}px`
      doc.style.height = `${aspectRatio.height}px`
    }
  }, [aspectRatio])

  useEffect(() => {
    const handleResize = () => {
      const getWidthHeight = (url) =>
        new Promise((resolve, reject) => {
          const img = new Image()
          img.onload = () => {
            resolve({ width: img.naturalWidth, height: img.naturalHeight })
          }
          img.onerror = (err) => reject(err)
          img.src = url
        })

      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth
        const containerHeight = containerRef.current.offsetHeight
        getWidthHeight(imgSource).then((naturalValues) => {
          const ratio = naturalValues.width / naturalValues.height
          const width = containerWidth
          const height = width / ratio
          if (height > containerHeight) {
            setAspectRatio({
              width: containerHeight * ratio,
              height: containerHeight,
              ratio
            })
            return
          }
          setAspectRatio({ width, height, ratio })
        })
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [imgSource])

  return aspectRatio
}

export default useAspectRatio

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { menu, DEFAULT, slice, configre } from '../../../constants/ui'

const { ADD_MENU, HIDE_MENU, SHOW_MENU } = menu

const {
  type: { STATIC_IMG, PANO },
  canvas: {
    draw: { SHOW_DRAW, ARROW_DRAW, CIRCE_DRAW, SQUARE_DRAW }
  },
  pano: {
    addOrEdit: { ADD_PANO, EDIT_PANO },
    spriteSelected: {
      CIRCE_DRAW_PANO,
      ARROW_DRAW_PANO,
      EDIT_DRAW_PANO,
      STAR_DRAW_PANO,
      MY_DRAW_PANO,
      SQUARE_DRAW_PANO
    }
  }
} = slice

const {
  header: {
    active: {
      ARROW_CONFIGRE,
      CIRCE_CONFIGRE,
      SQUARE_CONFIGRE,
      CIRCE_CONFIGRE_NANO,
      ARROW_CONFIGRE_PANO,
      CIRCE_CONFIGRE_PANO,
      SQUARE_CONFIGRE_PANO,
      STAR_CONFIGRE_PANO,
      MY_DRAW_CONFIGRE_PANO,
      EDIT_CONFIGRE_PANO
    },
    status: { ADD_CONFIGRE, ADD_CONFIGRE_PANO }
  },
  content: { ADD_MARKETS, ADD_SELECTED_MARKET, SHOW_CMT, PANO_ADD_SPRITES }
} = configre

const typeActiveHeaderConfigre = (typeDraw) => {
  switch (typeDraw) {
    case CIRCE_DRAW_PANO:
      return CIRCE_CONFIGRE_PANO
    case ARROW_DRAW_PANO:
      return ARROW_CONFIGRE_PANO
    case SQUARE_DRAW_PANO:
      return SQUARE_CONFIGRE_PANO
    case EDIT_DRAW_PANO:
      return EDIT_CONFIGRE_PANO
    case STAR_DRAW_PANO:
      return STAR_CONFIGRE_PANO
    case MY_DRAW_PANO:
      return MY_DRAW_CONFIGRE_PANO
    default:
      return DEFAULT
  }
}

const { CONTENT_MENUS, GALLERY_MENUS, DASHBOARD_MENUS } = menu
const initialState = {
  configre: {
    flat: false,
    cameraControls: {
      value: '',
      data: [],
      frameNum: 0
    },
    selectedTrimValue: { data: [], value: '' },
    selectedExterior: { data: [], value: '' },
    selectedInterior: { data: [], value: '' },
    showComments: false,
    header: {
      status: DEFAULT || ADD_CONFIGRE,
      active: DEFAULT || ARROW_CONFIGRE || CIRCE_CONFIGRE || SQUARE_CONFIGRE
    },
    content: {
      status: DEFAULT || ADD_MARKETS || ADD_SELECTED_MARKET || SHOW_CMT,
      descriptionFullText: false,
      comments: [],
      isComment: false,
      idSelectedComment: null
    },
    footer: DEFAULT || ADD_SELECTED_MARKET
  },
  slider: {
    data: [],
    pano: {
      viewMode: true,
      isShow: false,
      addOrEdit: DEFAULT || ADD_PANO || EDIT_PANO,
      spriteSelected: DEFAULT || CIRCE_CONFIGRE_NANO,
      panoComments: [],
      rawDraw: {},
      isChange: false,
      countChangePano: 0,
      panoHighlightedAnnotation: '',
      viewPosition: {
        pitch: -20,
        yaw: 0,
        hfov: 110
      },
      isCommentCreating: true,
      commentShape: 'circle',
      commentPosition: {
        pitch: -20,
        yaw: 0
      }
    },
    canvas: {
      status: false,
      draw: DEFAULT,
      data: {
        width: 0,
        arrows: [],
        circes: [],
        squares: []
      },
      rawDraw: {},
      index: null // or Id , (test)
    }
  },
  menu: {
    status: CONTENT_MENUS || GALLERY_MENUS || DASHBOARD_MENUS
  },
  saveComments: []
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setFlat: (state, action) => {
      state.configre.flat = action.payload
    },
    setCameraControl: (state, action) => {
      state.configre.cameraControls = {
        ...state.configre.cameraControls,
        ...action.payload
      }
    },
    setSelectedTrimValue: (state, action) => {
      state.configre.selectedTrimValue = action.payload
    },
    setSelectedExterior: (state, action) => {
      state.configre.selectedExterior = action.payload
    },
    setSelectedInterior: (state, action) => {
      state.configre.selectedInterior = action.payload
    },
    setImagesFromCDN: (state, action) => {
      state.slider.data = action.payload
    },
    clearState: (state) => {
      state.slider.data = []
    },
    clickMenu: (state, action) => {
      state.menu.data = action.payload
    },
    highlightAnnotation: (state, action) => {
      state.slider.pano.panoHighlightedAnnotation = action.payload.id
    },
    clickMenuPano: (state, action) => {
      state.configre.content.descriptionFullText = false
      state.slider.canvas.rawDraw = {}
      switch (action.payload) {
        case ADD_MENU:
          if (state.slider.type === STATIC_IMG) {
            state.slider.controls.disable = true
            state.slider.canvas.draw = SHOW_DRAW
            state.configre.header.active = DEFAULT
            state.configre.header.status = ADD_CONFIGRE
            state.configre.content.status = ADD_MARKETS
          } else if (state.slider.type === PANO) {
            state.slider.pano.viewMode = false
            state.slider.pano.isShow = true
            state.slider.pano.spriteSelected = DEFAULT
            state.configre.header.status = ADD_CONFIGRE_PANO
            state.configre.header.active = DEFAULT
            state.configre.content.status = PANO_ADD_SPRITES
          }

          break
        case SHOW_MENU:
          if (state.slider.type === STATIC_IMG) {
            state.configre.header.active = DEFAULT
            state.configre.header.status = DEFAULT
            state.configre.content.status = SHOW_CMT
            state.configre.footer = DEFAULT
            state.slider.canvas.draw = SHOW_DRAW
            state.slider.controls.disable = false
          } else {
            state.slider.pano.spriteSelected = DEFAULT
            state.slider.pano.viewMode = true
            state.slider.pano.isShow = true
            state.configre.header.active = DEFAULT
            state.configre.header.status = DEFAULT
            state.configre.content.status = SHOW_CMT
            state.configre.footer = DEFAULT
          }

          break
        case HIDE_MENU:
          if (state.slider.type === STATIC_IMG) {
            state.slider.canvas.draw = DEFAULT
            state.configre.header.status = DEFAULT
            state.configre.content.status = DEFAULT
            state.slider.controls.disable = false
          } else {
            state.slider.pano.spriteSelected = DEFAULT
            state.slider.pano.viewMode = true
            state.slider.pano.isShow = false
            state.configre.header.status = DEFAULT
            state.configre.content.status = DEFAULT
          }
          break
        default:
          return state
      }
    },
    loadTrimComments: (state, action) => {
      const trimComment = action.payload
        .map((data) => data.data)
        .filter(
          (commentType) =>
            commentType.commentType === 'trim_comment' ||
            commentType.commentType === 'general_comment' ||
            commentType.commentType === 'color_comment' ||
            commentType.commentType === 'camera_comment' ||
            commentType.commentType === 'config_comment'
        )
      state.saveComments = [...trimComment, { render: 'filter' }]
    },
    setSaveComments: (state, action) => {
      const newComment = action.payload
      const existingComments = JSON.parse(JSON.stringify(state.saveComments))
      console.log(state, action, 'SAVE COMMENTS')
      state.saveComments = [...existingComments, newComment]
    },
    setShowComments: (state, action) => {
      state.configre.showComments = action.payload
    },
    setCanvasEnabledPano: (state, action) => {
      state.slider.pano.canvasEnabled = action.payload
    },
    setPanoComments: (state, action) => {
      state.slider.pano.panoComments = action.payload
    },
    selectedMarketPano: (state, action) => {
      // configre
      state.configre.header.active = action.payload
      state.configre.content.status = ADD_SELECTED_MARKET
      state.configre.footer = ADD_SELECTED_MARKET
      state.configre.content.isComment = false
      // slice
      switch (action.payload) {
        case ARROW_CONFIGRE:
          state.slider.canvas.draw = ARROW_DRAW
          break
        case CIRCE_CONFIGRE:
          state.slider.canvas.draw = CIRCE_DRAW
          break
        case SQUARE_CONFIGRE:
          state.slider.canvas.draw = SQUARE_DRAW
          break
        default:
          return state
      }
      state.slider.canvas.status = true
    },
    screenshotMode: (state, action) => {
      if (action.payload.status) {
        state.slider.pano.panoComments = [state.slider.pano.rawDraw]
      } else {
        const saveComments = state.saveComments
        const comments = []
        const filterProjectComment = saveComments.filter(
          (projectId) =>
            projectId.projectId === action.payload.projectID &&
            projectId.cameraControlId ===
              action.payload.selectedCameraControlValue
        )
        // eslint-disable-next-line array-callback-return
        filterProjectComment.map((item) => {
          comments.push({
            commentDetail: item.commentDetail,
            ...item.canvas
          })
        })
        state.slider.pano.panoComments = comments
      }
    },
    saveRawDrawPano: (state, action) => {
      state.slider.pano.rawDraw = action.payload
    },
    setViewPano: (state, action) => {
      state.slider.pano.viewMode = action.payload
    },
    setSpriteSelectedPano: (state, action) => {
      state.configre.content.status = ADD_SELECTED_MARKET
      state.configre.content.isComment = false

      if (state.slider.pano.spriteSelected === action.payload) {
        state.slider.pano.spriteSelected = DEFAULT
        state.configre.header.active = DEFAULT
      } else {
        if (action.payload === MY_DRAW_PANO) {
          state.slider.pano.canvasEnabled = true
        } else {
          state.slider.pano.canvasEnabled = false
        }
        if (action.payload === EDIT_DRAW_PANO) {
          state.slider.pano.addOrEdit = EDIT_PANO
          state.slider.pano.isChange = false
        } else {
          state.slider.pano.addOrEdit = ADD_PANO
          state.configre.footer = ADD_SELECTED_MARKET
        }
        state.slider.pano.spriteSelected = action.payload
        state.configre.header.active = typeActiveHeaderConfigre(action.payload)
      }
    },
    setIsCommentModePano: (state, action) => {
      state.slider.pano.isCommentCreating = true
      state.slider.pano.commentShape = action.payload
    },
    setCommentPosition: (state, action) => {
      console.log(action.payload, 'ACTION PAYLOAD')
      state.slider.pano.commentPosition = action.payload
    },
    setIsChangePano: (state, action) => {
      state.slider.pano.isChange = action.payload
    },
    setIdSelectedComment: (state, action) => {
      state.configre.content.idSelectedComment = action.payload
    },
    changePanoViewPosition: (state, action) => {
      state.slider.pano.viewPosition = action.payload
    },
    setFrameNum: (state, action) => {
      state.configre.cameraControls.frameNum = action.payload
    }
  }
})

// export const fetchImagesFromCDN = createAsyncThunk(
//   'admin/fetchImages',
//   async (
//     { productName, interiorColor, exteriorColor, trim, version, year, flat },
//     thunkAPI
//   ) => {
//     return fetch(
//       `${
//         process.env.REACT_APP_HMA_CDN_URL
//       }&product__name=${productName}&intcolor=${
//         interiorColor || ''
//       }&extcolor=${exteriorColor}&fsc=${trim}&year=${year}&flat=${flat}`
//     )
//       .then((resp) => resp.json())
//       .then((data) => {
//         thunkAPI.dispatch(
//           uiSlice.actions.setImagesFromCDN(
//             data.results.filter(
//               (item) => parseFloat(item.latest_version) === version
//             )
//           )
//         )
//       })
//       .catch((error) => {
//         // handle error
//         console.log('error', error)
//       })
//   }
// )

export const fetchProjectComments = createAsyncThunk(
  'admin/fetchProjectComments',
  async ({ projectID }, thunkAPI) => {
    return fetch(`${process.env.REACT_APP_HISTORY_URL}/v1/history/${projectID}`)
      .then((resp) => resp.json())
      .then((data) => {
        console.log('project comments -->', data)
        if (data != null && data && data.data) {
          thunkAPI.dispatch(uiSlice.actions.loadTrimComments([...data.data]))
        }
      })
      .catch((error) => {
        // handle error
        console.log('error', error)
      })
  }
)

export const fetchTrimComments = createAsyncThunk(
  'admin/fetchProjectComments',
  async ({ projectID }, thunkAPI) => {
    return fetch(`${process.env.REACT_APP_HISTORY_URL}/v1/history/${projectID}`)
      .then((resp) => resp.json())
      .then((data) => {
        if (data != null && data && data.data) {
          thunkAPI.dispatch(uiSlice.actions.loadTrimComments([...data.data]))
        }
      })
      .catch((error) => {
        // handle error
        console.log('error', error)
      })
  }
)

export const saveProjectComments = createAsyncThunk(
  'admin/saveProjectComments',
  async ({ projectID, newComment }) => {
    return axios
      .post(`${process.env.REACT_APP_HISTORY_URL}/v1/history/${projectID}`, {
        data: { ...newComment }
      })
      .then(({ data }) => data)
      .catch((err) => console.log(err))
  }
)

export const saveAsTicket = createAsyncThunk(
  'admin/saveAsTicket',
  async ({ newComment }) => {
    return axios
      .post('https://jira-api-staging.thedigigi.net/v1/task', { ...newComment })
      .then(({ data }) => data)
      .catch((err) => console.log(err))
  }
)

const { actions, reducer } = uiSlice

export const {
  setFlat,
  setCameraControl,
  setSelectedTrimValue,
  setSelectedInterior,
  setSelectedExterior,
  loadAllComments,
  setCanvasEnabledPano,
  selectedMarketPano,
  setIdSelectedComment,
  changePanoViewPosition,
  setPanoComments,
  setViewPano,
  saveRawDrawPano,
  screenshotMode,
  setSpriteSelectedPano,
  setIsCommentModePano,
  setCommentPosition,
  setIsChangePano,
  setSaveComments,
  setShowComments,
  clearState,
  clickMenu,
  highlightAnnotation,
  clickMenuPano,
  setImagesFromCDN,
  setFrameNum
} = actions

export default reducer

import { configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/auth/authSlice'
import uiReducer from './reducers/ui/uiSlice'
import projectsReducer from './reducers/projects/projectsSlice'
import notificationBarReducer from './reducers/notificationBar/notificationBarSlice'
import canvasReducer from './reducers/canvas/canvasSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    projects: projectsReducer,
    ui: uiReducer,
    notificationBar: notificationBarReducer,
    canvas: canvasReducer
  }
})

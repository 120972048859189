import React from 'react'
import clsx from 'clsx'
import 'components/common/Icon/Icon.scss'
import propTypes from 'prop-types'

const Icon = (props) => {
  const { item, onClick, active, className, ...input } = props
  return (
    <span
      className={clsx('commonIcon', className || '', active ? 'active' : '')}
      onClick={onClick || undefined}
      {...input}
    >
      {item || ''}
      {props.children}
    </span>
  )
}

Icon.propTypes = {
  item: propTypes.any,
  onClick: propTypes.func,
  active: propTypes.bool,
  className: propTypes.string,
  children: propTypes.any
}

export default Icon

import React from 'react'
import propTypes from 'prop-types'
import { GenesisLogoImg, HyundaiLogoImg, SubaruLogoImg } from '../icons/icons'
import Icon from '../common/Icon/Icon'
import { GENESIS, HYUNDAI, SUBARU } from '../../constants/constants'

function BrandIcon ({ brand }) {
  return (
    <div className='brand-logo'>
      <Icon
        className={brand === HYUNDAI || brand === SUBARU ? 'brand-logo_hyundai_size' : 'brand-logo_genesis_size'}
        item={brand === HYUNDAI ? <HyundaiLogoImg /> : brand === SUBARU ? <SubaruLogoImg /> : brand === GENESIS ? <GenesisLogoImg /> : <></>}
      />
    </div>
  )
}

BrandIcon.propTypes = {
  brand: propTypes.string.isRequired
}

export default BrandIcon
